import React, { useEffect,useMemo, useState } from "react";
import { compose } from "redux";
import { connect } from "react-redux";
import { withRouter } from "react-router";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import moment from "moment";
import findIndex from "lodash/findIndex";
import includes from "lodash/includes";
import orderBy from "lodash/orderBy";
import * as ProviderApi from "api/ProviderApi";
import * as ProviderActions from "actions/ProviderActions";
import AccessDenied from "components/common/AccessDenied";
import Button from "components/common/Button";
import GenericGame from "components/games/elements/GenericGame/GenericGame";
import GenericGame2024 from "components/games/elements/GenericGame/GenericGame2024";
import GoldenSummerGames from "components/games/pages/ClinicGamePage/GoldenSummerGames";
import GreenlineGameCard from "components/games/elements/GreenlineGameCard";
import Modal from "components/common/Modal";
import PuppyBowl2 from "components/games/pages/ClinicGamePage/PuppyBowl2";
import PuppyBowlSnapshot from "components/games/widgets/PuppyBowlSnapshot";
import PuppyLeafPartySnapshot from "./PuppyLeafPartySnapshot/PuppyLeafPartySnapshot";
import PuppyPoolPartySnapshot from "components/games/widgets/PuppyPoolPartySnapshot";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import { handleErrorResponse } from "utils/request";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import {
    ANIMATED_GAMES,
    GOLDEN_SUMMER,
    GRAND_SLAM,
    LEAF_PARTY,
    PUPPY_BOWL,
    PUPPY_BOWL_2,
    PUPPY_POOL_PARTY
} from "constants/Games/GameConstants";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";
import GrandSlamGame from "../pages/ClinicGamePage/GrandSlamGame";

function GreenlineGameSnapshot(props) {
    const {
        canViewGames = false,
        game,
        handleClose,
        isProvider = false,
        loadSnapshot,
        nodeId = null,
        show = false,
        snapshots = [],
        userId = null,
    } = props;

    const { pathname } = useLocation();
    const isDemo = !!includes(pathname, "/demo");
    const [loading, setLoading] = useState(false);
    const [gameSnapshotId, setGameSnapshotId] = useState(!!game?.mostRecentGameSnapshotId ? game.mostRecentGameSnapshotId : null);
    const [snapshotList, setSnapshotList] = useState([]);
    const snapshot = snapshots[gameSnapshotId] ? snapshots[gameSnapshotId] : null;
    const currentIndex = (snapshotList.length && gameSnapshotId) ? findIndex(snapshotList, s => s.gameSnapshotId === gameSnapshotId) : -1;
    const canGoForward = currentIndex > 0;
    const canGoBackward = currentIndex < (snapshotList.length - 1);
    const isFullSize = useMemo(() => ANIMATED_GAMES[game?.gameType], [game]);

    const gameProperties = useMemo(() => {
        if (game) {
            switch (game.gameType) {
                case PUPPY_BOWL_2:
                    return {
                        bannerUrl: "https://glprodcdnst.blob.core.windows.net/games/assets/petsbowl/logos/Clinic_Rep_Leaderboard_Pets_Bowl_Banner_Image.jpg",
                        bannerAlt: "HeartGard® Plus+ and NexGard® Plus+ advertisement",
                        board: PuppyBowl2,
                        usePoints: false,
                    };
                case GOLDEN_SUMMER:
                    return {
                        bannerUrl: "https://cdn.greenlinepet.com/games/assets/GoldenGames/GoldenGames Banner_970x90_r1v1.jpg",
                        bannerAlt: "Golden Summer Games",
                        board: GoldenSummerGames,
                        usePoints: true,
                    };
                case GRAND_SLAM:
                    return {
                        bannerUrl: "https://glprodcdnst.blob.core.windows.net/games/assets/GrandSlam/BIAHU-004763_CMK_2024_FallGreenlineGames_Banners_970x89.jpg",
                        bannerAlt: "Grand Slam Game",
                        board: GrandSlamGame,
                        usePoints: true,
                    };
                default:
                    // Do nothing
            }
        }
        return null;
    }, [game]);

    const loadSnapshots = async (gameId, onComplete) => {
        try {
            const res = await ProviderApi.getSnapshots(gameId, userId, isDemo);
            onComplete(res.body);
        } catch (error) {
            handleErrorResponse("loading game snapshots", error);
            setSnapshotList([]);
            setLoading(false);
        }
    };

    useEffect(() => {
        if(game && canViewGames) {
            setGameSnapshotId(game.mostRecentGameSnapshotId);
            setLoading(true);

            loadSnapshots(game.greenlineGameId, response => {
                setSnapshotList(orderBy(response, "daysFromStart", "desc"));
                setLoading(false);
            });
        }
    }, [game]);

    useEffect(() => {
        if(gameSnapshotId && !snapshot && canViewGames) {
            loadSnapshot(gameSnapshotId, userId, isDemo);
        }
    }, [gameSnapshotId]);

    const handleForward = () => {
        const nextId = snapshotList[currentIndex - 1].gameSnapshotId;
        setGameSnapshotId(nextId);
    }

    const handleBackward = () => {
        const prevId = snapshotList[currentIndex + 1].gameSnapshotId;
        setGameSnapshotId(prevId);
    }

    const renderGameCard = () => {
        switch (game.gameType) {
            case PUPPY_BOWL:
                return (
                    <PuppyBowlSnapshot
                        game={snapshot}
                        clinicId={snapshot ? snapshot.clinicId : null}
                        nodeId={nodeId}
                        animate={!canGoBackward}
                    />
                );
            case PUPPY_POOL_PARTY:
                return (
                    <PuppyPoolPartySnapshot
                        game={snapshot}
                        clinicId={snapshot?.clinicId || null}
                        nodeId={nodeId}
                        animate={!canGoForward}
                    />
                );
            case LEAF_PARTY:
                return (
                    <PuppyLeafPartySnapshot
                        animate={!canGoForward}
                        isProvider={isProvider}
                        clinicId={snapshot?.clinicId || null}
                        game={snapshot}
                        nodeId={nodeId}
                    />
                )
            case PUPPY_BOWL_2:
            case GOLDEN_SUMMER:
                return (
                    <GenericGame
                        animate={!canGoForward}
                        clinicId={snapshot?.clinicId || null}
                        game={snapshot}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        nodeId={nodeId}
                        snapshot
                        usePoints={gameProperties.usePoints}
                    />
                );
            case GRAND_SLAM:
                return (
                    <GenericGame2024
                        animate={!canGoForward}
                        clinicId={snapshot?.clinicId || null}
                        game={snapshot}
                        bannerAd={gameProperties.bannerUrl}
                        bannerAdAltText={gameProperties.bannerAlt}
                        Leaderboard={gameProperties.board}
                        nodeId={nodeId}
                        snapshot
                        usePoints={gameProperties.usePoints}
                    />
                );
            default:
                return(
                    <GreenlineGameCard
                        // Have to get includeHomeDeliveryDoses from the game
                        game={{...snapshot, includeHomeDeliveryDoses: game?.includeHomeDeliveryDoses}}
                        clinicId={snapshot ? snapshot.clinicId : null}
                        nodeId={nodeId}
                    />
                );
        }
    }

    return (
        <Modal
            show={show}
            onClose={handleClose}
            mediumSmall={!isFullSize}
            modalType={isFullSize ? "FULLSCREEN" : null}
            icon="fas fa-chart-line"
            iconFontSize={3}
            modalTitle="Game Progress"
            modalSubTitle={`Report Date ${!!snapshot ?  moment(snapshot.reportDate).format("MM/DD/YYYY") : ""}`}
        >
            {canViewGames ? (
                <>
                    <div className="flex flex-centered">
                        {!!snapshotList?.length && (
                            <div className="flex-none flex align-top margin-right-md">
                                <Button
                                    text
                                    disabled={!canGoBackward}
                                    onClick={handleBackward}
                                    type="success"
                                >
                                    <i className="fa fa-2x fa-chevron-circle-left"/>
                                </Button>
                            </div>
                        )}
                        {!!snapshot && renderGameCard()}
                        {!!snapshotList?.length && (
                            <div className="flex-none flex align-top margin-left-md">
                                <Button
                                    text
                                    disabled={!canGoForward}
                                    onClick={handleForward}
                                    type="success"
                                >
                                    <i className="fa fa-2x fa-chevron-circle-right"/>
                                </Button>
                            </div>
                        )}
                    </div>
                    <SpinnerTakeover show={snapshots?.loading || loading}/>
                </>
            ) : <AccessDenied/>}
        </Modal>
    );
}

GreenlineGameSnapshot.propTypes = {
    game: PropTypes.object.isRequired,
    handleClose: PropTypes.func.isRequired,
    isProvider: PropTypes.bool,
    nodeId: PropTypes.number,
    userId: PropTypes.number,
};

const connector = connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        // Permissions
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canViewProviderBiGames = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES, userProfile);
        const canViewProviderBiGamesDrilldown = userHasPermission(PermissionTypes.VIEW, UserPermissions.PROVIDER_BI_GAMES_DRILLDOWN, userProfile);
        const canViewGames = canViewProviderBiGames || canViewProviderBiGamesDrilldown;
        return {
            isProvider,
            snapshots: state.entities.snapshots || {},
            // Permissions
            canViewClinicBIGamesDashboard,
            canViewGames,
        }
    },
    (dispatch) => ({
        loadSnapshot: (snapshotId, userId, isDemo) => dispatch(ProviderActions.getSnapshot(snapshotId, userId, isDemo)),
    })
);

export default compose(
    withRouter,
    connector
)(GreenlineGameSnapshot);
