import React from "react";
import {compose} from "redux";
import {withRouter} from "react-router";
import { Link } from "react-router-dom";
import PropTypes from "prop-types";
import styles from "./GamesDashboardWidget.scss";
import { map } from "lodash";
import GreenlineGameCard from "components/games/elements/GreenlineGameCard";
import { GAME_TYPES } from "constants/Games/GameConstants";

function GamesDashboardWidget(props) {

    const handleCardClick = game => {
        switch(game.gameType) {
            case GAME_TYPES.ON_GARD:
                if (props.isAdminView) {
                    props.history.push(`/admin/clinic/${props.clinicId}/games`);
                } else if(props.isProviderView) {
                    props.history.push(`/provider/location/${props.nodeId}/clinic/${props.clinicId}/games`);
                } else {
                    props.history.push("/games");
                }
                break;
            case GAME_TYPES.PUPPY_BOWL:
            case GAME_TYPES.PUPPY_POOL_PARTY:
            case GAME_TYPES.LEAF_PARTY:
                if (props.isAdminView) {
                    props.history.push(`/admin/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
                } else if(props.isProviderView) {
                    props.history.push(`/provider/location/${props.nodeId}/clinic/${props.clinicId}/games/${game.greenlineGameId}`);
                } else {
                    props.history.push(`/games/${game.greenlineGameId}`);
                }
                break;
        }
    };

    const renderGames = () => {
        if (!props.games) {
            return <div>No Active Games</div>;
        }

        return map(props.games, game => {
            return (
                <div
                    key={`game_${game.greenlineGameId}`}
                    className={styles.gameCards}
                >
                    <GreenlineGameCard
                        dashboard
                        game={game}
                        key={`game_${game.greenlineGameId}`}
                        clinicId={props.clinicId}
                        onCardClick={handleCardClick}
                        nodeId={props.nodeId}
                    />
                </div>
            );
        });
    }

    return (
        <>
            <div className="sectionHeader">
                <h3>Greenline Games</h3>
                <Link to={`${props.basePath}/games`} className="text-primary flex-1 text-right">
                    View all <i className="fas fa-chevron-right" />
                </Link>
            </div>
            <div className={styles.root}>
                {renderGames()}
            </div>
        </>
    );
}

GamesDashboardWidget.propTypes = {
    games: PropTypes.array,
    basePath: PropTypes.string.isRequired,
    clinicId: PropTypes.number,
    nodeId: PropTypes.number,
    isAdminView: PropTypes.bool,
    isProviderView: PropTypes.bool,
};

export default compose(
    withRouter,
)(GamesDashboardWidget);
