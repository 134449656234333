exports = module.exports = require("../../../../../../node_modules/css-loader/lib/css-base.js")(true);
// imports


// module
exports.push([module.id, ".GenericAnimation__root{position:absolute;transform:translate(-50%, -50%);overflow:hidden;opacity:1;top:50%;left:100%}.GenericAnimation__root .GenericAnimation__svgContainer{position:absolute;height:100%;width:auto;top:0}.GenericAnimation__root .GenericAnimation__svgContainer>svg{height:100%;width:auto}.GenericAnimation__root .GenericAnimation__svgContainer>img{height:100%;width:auto}.GenericAnimation__root.GenericAnimation__transparent{opacity:.7}\n", "", {"version":3,"sources":["/home/runner/work/greenline-web/greenline-web/web/js/components/games/elements/GenericGame/GenericAnimation.scss"],"names":[],"mappings":"AAAA,wBAAM,kBAAkB,gCAAgC,gBAAgB,UAAU,QAAQ,SAAS,CAAC,wDAAoB,kBAAkB,YAAY,WAAW,KAAK,CAAC,4DAAwB,YAAY,UAAU,CAAC,4DAAwB,YAAY,UAAU,CAAC,sDAAkB,UAAU,CAAC","file":"GenericAnimation.scss","sourcesContent":[".root{position:absolute;transform:translate(-50%, -50%);overflow:hidden;opacity:1;top:50%;left:100%}.root .svgContainer{position:absolute;height:100%;width:auto;top:0}.root .svgContainer>svg{height:100%;width:auto}.root .svgContainer>img{height:100%;width:auto}.root.transparent{opacity:.7}\n"],"sourceRoot":""}]);

// exports
exports.locals = {
	"root": "GenericAnimation__root",
	"svgContainer": "GenericAnimation__svgContainer",
	"transparent": "GenericAnimation__transparent"
};