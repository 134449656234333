import React from "react";
import PropTypes from "prop-types";
import styles from "./GoldenSummerGamesPodium.scss";
import map from "lodash/map";
import take from "lodash/take";
import Image from "components/common/Image";

const Podium = require("./images/Podium@1.5x.png");

// Kitty Sprites
import KittyPodiumCenter from "./KittyPodiumCenter";
import KittyPodiumLeft from "./KittyPodiumLeft";
import KittyPodiumRight from "./KittyPodiumRight";

// Puppy Sprites
import PuppyPodiumCenter from "./PuppyPodiumCenter";
import PuppyPodiumLeft from "./PuppyPodiumLeft";
import PuppyPodiumRight from "./PuppyPodiumRight";
import GenericAnimation from "../../../elements/GenericGame/GenericAnimation";

const SECOND = 1000;
const MINUTE = SECOND * 60;

const animationDuration = 10 * SECOND;
const duration_2 = SECOND / 3;
const duration_4 = SECOND / 1.5;

const BASE_ANIMATION = {
    animationDuration,
    duration: SECOND,
    isAnimated: true,
}
export default function GoldenSummerGamesPodium({ AvatarType, data }) {
    const CENTER_PET_FRAMES = [{
        top: 0,
        left: 0
    }, {
        top: 0,
        left: "-100%"
    }];

    const RIGHT_PET_FRAMES = [{
        top: 0,
        left: 0
    }];

    const LEFT_PET_FRAMES = [{
        top: 0,
        left: 0
    }];

    return (
        <div className={styles.root}>
            <div className={styles.backgroundContainer}>
                <Image src={Podium} alt="Podium" />
            </div>
            {map(take(data, 3), (row, index) => {
                let animation = {};
                switch (index) {
                    case 0:
                        if (row.AvatarType === "cat") {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Winning Kitty",
                                frames: CENTER_PET_FRAMES,
                                height: "18em",
                                image: <KittyPodiumCenter teamNumber={row.Place}/>,
                                width: "24.2em"
                            }
                        } else {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Winning Puppy",
                                frames: CENTER_PET_FRAMES,
                                height: "18em",
                                image: <PuppyPodiumCenter teamNumber={row.Place}/>,
                                width: "23.5em"
                            };
                        }
                        return (
                            <div
                                className={styles.centerPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    <GenericAnimation
                                        animation={animation}
                                    />
                                </div>
                            </div>
                        );
                    case 1:
                        if(AvatarType === "cat") {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Second Place Kitty",
                                frames: RIGHT_PET_FRAMES,
                                height: "16em",
                                image: <KittyPodiumRight teamNumber={row.Place}/>,
                                width: "20em"
                            };
                        } else {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Second Place Puppy",
                                frames: RIGHT_PET_FRAMES,
                                height: "16em",
                                image: <PuppyPodiumRight teamNumber={row.Place}/>,
                                width: "20em"
                            };
                        }
                        return (
                            <div
                                className={styles.rightPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    <div className={styles.animationContainer}>
                                        <GenericAnimation
                                            animation={animation}
                                        />
                                    </div>
                                </div>
                            </div>
                        );
                    case 2:
                        if(AvatarType === "cat") {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Third Place Kitty",
                                frames: LEFT_PET_FRAMES,
                                height: "16em",
                                image: <KittyPodiumLeft teamNumber={row.Place}/>,
                                width: "20em"
                            };
                        } else {
                            animation = {
                                ...BASE_ANIMATION,
                                altText: "Second Place Puppy",
                                frames: LEFT_PET_FRAMES,
                                height: "16em",
                                image: <PuppyPodiumLeft teamNumber={row.Place}/>,
                                width: "20em"
                            };
                        }
                        return (
                            <div
                                className={styles.leftPet}
                                key={row.ClinicId}
                            >
                                <div className={styles.animationContainer}>
                                    <GenericAnimation
                                        animation={animation}
                                    />
                                </div>
                            </div>
                        );
                }
            })}
        </div>
    );
}

GoldenSummerGamesPodium.defaultProps = {
    data: [],
};

GoldenSummerGamesPodium.propTypes = {
    data: PropTypes.array,
};
