import React from "react";

export const NEXGARD = "Nexgard";
export const NEXGARD_PLUS = "Nexgard Plus";
export const NEXGARD_COMBO = "Nexgard Combo";
export const HEARTGARD = "Heartgard";
export const HEARTGARD_PLUS = "Heartgard Plus";

export const ProductProperNames = {
    [NEXGARD]: <span><i>NexGard</i>®</span>,
    [NEXGARD_PLUS]: <span><i>NexGard</i>® PLUS</span>,
    [NEXGARD_COMBO]: <span><i>NexGard</i>® COMBO™</span>,
    [HEARTGARD]: <span>HEARTGARD®</span>,
    [HEARTGARD_PLUS]: <span>HEARTGARD® Plus</span>,
}
