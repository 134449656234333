import React, { Fragment } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import classnames from "classnames";
import * as styles from "./GameParasiticideProductsTable.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import flatMap from "lodash/flatMap";
import includes from "lodash/includes";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import SpinnerTakeover from "components/common/SpinnerTakeover";
import Tooltip from "components/common/Tooltip";
import getProviderId from "utils/getProviderId";
import { formatCurrencyString } from "utils/numeric";
import { DEATH_MATCH, DEATH_MATCH_NGP, GROWTH_NGP, LEAF_PARTY, PUPPY_BOWL, PUPPY_BOWL_2, PUPPY_POOL_PARTY } from "constants/Games/GameConstants";

const GAMES_WITHOUT_POINTS = [
    DEATH_MATCH,
    DEATH_MATCH_NGP,
    PUPPY_POOL_PARTY,
    LEAF_PARTY,
    PUPPY_BOWL,
    PUPPY_BOWL_2,
];

function DeathMatchReview(props) {
    const { reviewData, data } = props;
    const { clinics } = data;
    const selectedClinicIds = flatMap(data.clinics, "clinicId");
    const selectedProductIds = flatMap(data.products, "productBrandId");
    const hasMultipleClinics = !!(clinics && clinics.length && clinics.length > 1);
    const isGrowthNgp = data.gameType === GROWTH_NGP;
    const isDeathmatchNgp = data.gameType === DEATH_MATCH_NGP;
    const showPoints = !GAMES_WITHOUT_POINTS.includes(data.gameType);

    const getClinicName = (row, doses, points) => {
        const isActualBrand = row.brandName !== "Total";
        const hasDoseCount = (!!doses || doses === 0) && doses !== -1;
        // const hasPointCount = showPoints && !!points && points !== -1;
        let tip = null;

        if (isActualBrand && doses === 0) {
            tip = `${row.clinicName || "Clinic"} did not sell ${row.brandName} during this time frame`;
        }

        if ((isGrowthNgp || isDeathmatchNgp) && row.brandName.toLowerCase() === "nexgard plus") {
            tip = "Clinic did not dispense either Heartgard or NexGard during this timeframe."
        }

        if (tip) {
            return (
                <Tooltip key={`row_${row.clinicId}`} position="top" tip={tip}>
                    <div style={{ lineHeight: 1, padding: "0.25em", cursor: "pointer" }} title={tip}>
                        <i className="fa fa-exclamation-triangle padding-right-sm" />
                        {row.clinicName}
                    </div>
                </Tooltip>
            );
        }

        return <span>{row.clinicName}</span>
    }

    const renderBrandName = (row) => {
        const {brandName} = row;
        if ((isGrowthNgp || isDeathmatchNgp) && brandName.toLowerCase() === "nexgard plus") {
            let tipMessage = "This is a combination of Heartgard/NexGard dispensed during this timeframe";

            return (
                <div className={styles.extraInfo}>
                    <Tooltip
                        key={`brand_${brandName}`}
                        position="top"
                        tip={
                            <div>
                                {tipMessage}
                            </div>
                        }
                    >
                        <div>
                            <i className={"fas fa-eye"} />
                        </div>
                    </Tooltip>
                    <span>{brandName}</span>
                </div>
            )
        }
        return brandName;
    }

    const ROW = (row, index, isFirstRow = false) => {
        const isTotal = row.brandName === "Total";
        //Handle Include Free Doses switch
        let doses = (data.excludeFreeDoses ? row.pPaidDoses : row.pDoses) || 0;
        //Handle Include Home Delivery Doses switch
        doses = data.includeHomeDeliveryDoses ? (doses + row.homePDoses || 0) : doses;

        const points = row.pPoints || 0;

        return (
            <tr
                key={index}
                className={classnames("spaced-content", {
                    [styles.firstRow]: isFirstRow,
                    [styles.red]: !isTotal && 0 <= doses && doses < 200,
                    [styles.yellow]: !isTotal && 200 <= doses && doses < 1000,
                    [styles.green]: !isTotal && 1000 <= doses,
                })}
            >
                <td className={classnames({
                    "nowrap": isFirstRow
                })}>
                    {getClinicName(row, doses, points)}
                </td>
                <td className="nowrap">{renderBrandName(row)}</td>
                <td className="text-right">{formatCurrencyString(doses)}</td>
                {showPoints && <td className="text-right">{formatCurrencyString(points)}</td>}
            </tr>
        )
    };

    const MOBILE_ROW = (row, index, isFirstRow=false) => {
        //Handle Include Free Doses switch
        let doses = data.excludeFreeDoses ? row.pPaidDoses : row.pDoses
        //Handle Include Home Delivery Doses switch
        doses = data.includeHomeDeliveryDoses ? (doses + row.homePDoses) : doses;

        const points = row.pPoints;
        const rowTotalJsx = showPoints ? (
            <span><b>Total:</b> {formatCurrencyString(doses)} doses / {formatCurrencyString(points)} points</span>
        ) : (
            <span><b>Total:</b> {formatCurrencyString(doses)} doses</span>
        );

        if (row.brandName === "Total") {
            return (
                <tr
                    key={index}
                    className={"spaced-content"}
                >
                    <td>
                        {rowTotalJsx}
                    </td>
                </tr>
            )
        }

        return (
            <>
                {isFirstRow && (
                    <tr className={styles.firstRow} key={`first_${index}`}>
                        <td className="nowrap">{row.clinicName}</td>
                    </tr>
                )}
                <tr
                    key={index}
                    className={"spaced-content"}
                >
                    <td className={classnames("flex spaced-content align-center", {
                        [styles.red]: 0 <= doses && doses < 200,
                        [styles.yellow]: 200 <= doses && doses < 1000,
                        [styles.green]: 1000 <= doses,
                    })}>
                        {((doses === -1 || !doses) || (showPoints && (points === -1 || !points))) && (
                            <i className="fa fa-exclamation-triangle padding-right-sm" />
                        )}
                        <div>
                            <b>Product:</b> {row.brandName}<br/>
                            <b>Doses:</b> {formatCurrencyString(doses)}<br />
                            {showPoints && <span><b>Points:</b> {formatCurrencyString(points)}</span>}
                        </div>
                    </td>
                </tr>
            </>
        )
    };

    let MOBILE_SECTIONS = [];
    let SECTIONS = map(selectedClinicIds, (clinicId) => {

        const clinicData = reviewData ? orderBy(filter(reviewData.data, c => {
            return c.clinicId === clinicId && includes(selectedProductIds, c.productBrandId)
        }), ["brandName"], ["asc"]) : [];
        let firstRow = true;

        if (clinicData.length) {
            const ngPlus = find(clinicData, p => p.brandName.toLowerCase() === "nexgard plus");
            if (ngPlus && ngPlus.ngBaseDoseCount) {
                const ngCount = {
                    brandName: "NexGard",
                    pDoses: ngPlus.ngBaseDoseCount|| 0,
                    pPaidDoses: ngPlus.ngBasePaidDoseCount || 0,
                    homePDoses: 0,
                    pPoints: ngPlus.pPoints,
                }
                clinicData.push(ngCount);
                const hgCount = {
                    brandName: "Heartgard",
                    pDoses: ngPlus.hgBaseDoseCount || 0,
                    pPaidDoses: ngPlus.hgBasePaidDoseCount || 0,
                    homePDoses: 0,
                    pPoints: ngPlus.pPoints,
                }
                clinicData.push(hgCount);
            }

            let ptotal = 0;
            let pPaidtotal = 0;
            let homePDosesTotal = 0;
            let pPoints = 0;
            map(clinicData, c => {
                if (c.pDoses === -1) c.pDoses = 0;
                if (c.pPaidDoses === -1) c.pPaidDoses = 0;
                if (c.homePDoses === -1) c.homePDoses = 0;
                ptotal += c.pDoses;
                pPaidtotal += c.pPaidDoses;
                homePDosesTotal += (c.homePDoses || 0)
                pPoints += (c.pPoints || 0)
            });
            const totalRow = {
                brandName: "Total",
                pDoses: ptotal,
                pPaidDoses: pPaidtotal,
                homePDoses: homePDosesTotal,
                pPoints: pPoints,
            }
            clinicData.push(totalRow);

            const tableRows = (
                <Fragment key={clinicId}>
                    {map(clinicData, (c, i) => {
                        const row = (
                            ROW(c, i, firstRow)
                        );
                        firstRow = false;
                        return row;
                    })}
                </Fragment>
            );

            firstRow = true;
            MOBILE_SECTIONS.push(
                <Fragment key={clinicId}>
                    {map(clinicData, (c, i) => {
                        const row = (
                            MOBILE_ROW(c, i, firstRow)
                        );
                        firstRow = false;
                        return row;
                    })}
                </Fragment>
            );

            return tableRows;
        }
    });

    SECTIONS = filter(SECTIONS, s => {
        return !!s;
    })

    MOBILE_SECTIONS = filter(MOBILE_SECTIONS, s => {
        return !!s;
    })

    const renderFullTable = () => (
        <table className={classnames("table margin-top-x-sm", styles.fullTable)}>
            <thead>
                <tr className="spaced-content">
                    <th>Clinic</th>
                    <th>Product</th>
                    <th className="text-right">Doses</th>
                    {showPoints && <th className="text-right">Points</th>}
                </tr>
            </thead>
            <tbody>
                {!!SECTIONS ? SECTIONS.length ? SECTIONS : (
                    <tr>
                        <td className="text-center" colSpan={3}>
                            No Data to Display for the Selected Clinics, Products, and Start Date.
                        </td>
                    </tr>
                ) : (
                    <tr>
                        <td>
                            <SpinnerTakeover show />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );

    const renderMobileView = () => (
        <table className={classnames("table margin-top-x-sm", styles.mobileView)}>
            <thead>
                <tr className="spaced-content">
                    <th>Clinics</th>
                </tr>
            </thead>
            <tbody>
                {!!MOBILE_SECTIONS ? MOBILE_SECTIONS.length ? MOBILE_SECTIONS : (
                    <tr>
                        <td className="text-center" colSpan={3}>
                            No Data to Display for the Selected Clinics, Products, and Start Date.
                        </td>
                    </tr>
                ) : (
                    <tr>
                        <td>
                            <SpinnerTakeover show />
                        </td>
                    </tr>
                )}
            </tbody>
        </table>
    );

    return (
        <div className="full-width full-height margin-top-sm">
            <div>
                <div className="flex spaced-content">
                    <h2 className="flex-1">{data.productType} Products</h2>
                </div>
                {reviewData &&
                    <span>({reviewData.dates?.startDateLastPeriod} - {reviewData.dates?.endDateLastPeriod})</span>
                }
            </div>
            {(!reviewData && !((hasMultipleClinics) && data.startDate)) &&
                <div className="flex justify-center margin-top-sm" style={{ color: "#aaa" }}>
                    <h3>Select {!(hasMultipleClinics) && "Clinics"} {!((hasMultipleClinics) || data.startDate) && "and"} {!data.startDate && "the Start Date"} to View Data</h3>
                </div>
            }
            {reviewData &&
                <div className={styles.reviewData}>
                    <div className={classnames(styles.root, "overflow-x-scroll full-width")}>
                        {renderFullTable()}
                        {renderMobileView()}
                    </div>
                </div>
            }
        </div>
    );
}

DeathMatchReview.propTypes = {
    data: PropTypes.object,
    reviewData: PropTypes.object,
    readOnly: PropTypes.bool,
}

export default connect(
    (state, ownProps) => {
        const params = ownProps?.match?.params || {};
        const userProfile = state.user.userProfile;
        const providerId = getProviderId(params, userProfile);
        return {
            providerId,
        };
    }
)(DeathMatchReview);
