import get from "lodash/get";
import {
    GAME_COMPARISON_PERIODS,
    GAME_DURATIONS,
    GAME_PRODUCT_TYPES,
    GAME_TYPE_CONFIGS,
    GAME_TYPES,
} from "constants/Games/GameConstants";

export function getIsChallengeMatchGame(gameType) {
    return get(GAME_TYPE_CONFIGS, `[${gameType}].isChallenge`) || false;
}

export function getNameForGameComparisonPeriod(period) {
    switch (period) {
        case GAME_COMPARISON_PERIODS.YOY:
            return "Year-over-Year";
        case GAME_COMPARISON_PERIODS.ROLLING:
            return "Rolling Period";
    }

    return period;
}

export function getCategoryNameForGameType(productType) {
    switch (productType) {
        case GAME_PRODUCT_TYPES.THERAPEUTIC:
            return "Other"
        case GAME_PRODUCT_TYPES.PARASITICIDE:
            return "Parasiticide Products";
        case GAME_PRODUCT_TYPES.DENTAL:
            return "Dental Products";
        case GAME_PRODUCT_TYPES.NSAID:
            return "NSAIDs";
    }

    return productType;
}

export function getCategoryIdForGameProductType(productType) {
    switch (productType) {
        case GAME_PRODUCT_TYPES.PARASITICIDE:
            return 30;
        case GAME_PRODUCT_TYPES.DENTAL:
            return 60;
        case GAME_PRODUCT_TYPES.NSAID:
            return 20;
        case GAME_PRODUCT_TYPES.NUTRACEUTICAL:
            return 70;
        case GAME_PRODUCT_TYPES.SERVICE:
            return 50;
        case GAME_PRODUCT_TYPES.THERAPEUTIC:
            return 40;
    }
    return productType;
}

export function getNameForGameDuration(duration) {
    switch (duration.toString()) {
        case GAME_DURATIONS.SEVEN:
            return "7 Days";
        case GAME_DURATIONS.FOURTEEN:
            return "14 Days";
        case GAME_DURATIONS.THIRTY:
            return "30 Days";
        case GAME_DURATIONS.SIXTY:
            return "60 Days";
        case GAME_DURATIONS.NINETY:
            return "90 Days";
    }

    return `${duration} Days`;
}
