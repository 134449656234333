import React, {useMemo, useState} from "react";
import PropTypes from "prop-types";
import JumbotronLeaderboard from "components/games/elements/GenericGame/JumbotronLeaderboard";
import {GRAND_SLAM} from "constants/Games/GameConstants";
const BaseballScoreboard = "https://glprodcdnst.blob.core.windows.net/games/assets/GrandSlam/animations/Leaderboard.png";

export default function GrandSlamGame({
    animate,
    backgroundBase64,
    currentClinicId,
    data = [],
    currentEvent,
    daysRemaining,
    printing,
    showAllPoints,
    snapshot,
}) {
    const config = useMemo(() => {
        return  {
            background: backgroundBase64 || BaseballScoreboard,
            backgroundAlt: "Baseball Scoreboard",
        };
    }, []);

    return (
        <>
            <JumbotronLeaderboard
                animate={animate}
                backgroundImage={config.background}
                backgroundImageAlt={config.backgroundAlt}
                currentClinicId={currentClinicId}
                currentEvent={currentEvent}
                data={data}
                daysRemaining={daysRemaining}
                gameType={GRAND_SLAM}
                printing={printing}
                showAllPoints={showAllPoints}
                snapshot={snapshot}
                onlyPaid
                points
            />
        </>
    );
}

GrandSlamGame.propTypes = {
    animate: PropTypes.bool,
    backgroundBase64: PropTypes.string,
    currentClinicId: PropTypes.number,
    data: PropTypes.array,
    currentEvent: PropTypes.shape({
        DaysRemaining: PropTypes.number,
        EventId: PropTypes.number,
    }),
    daysRemaining: PropTypes.number,
    printing: PropTypes.bool,
    showAllPoints: PropTypes.bool,
    snapshot: PropTypes.bool,
};
