import times from "lodash/times";
import forEach from "lodash/forEach";


//=----------------------------------------------------------------------------
// Full-frame animation helpers.
//=----------------------------------------------------------------------------

export function setUpAnimation(frameCount, frameCountX, width, height, fps = 15) {

    const duration = getFrameDuration(frameCount, fps);
    const heightScale = getFrameHeightScale(frameCountX, frameCount);
    const frames = createFrames(frameCountX, frameCount, width, height);

    return {
        frames,
        duration,
        heightScale,
    }
}

export function getFrameDuration(frameCount, fps = 15) {
    return Math.floor(1000 / fps) * frameCount;
}

export function getFrameHeightScale(frameCountX, frameCountTotal) {
    const rows = Math.ceil(frameCountTotal / frameCountX);
    return 100 / (100 / rows) * 100;
}

export function createFrames(frameCountX, frameCountTotal, width, height) {
    // const height = 100 / Math.floor(frameCountTotal / frameCountX);
    // const width = height * aspectRatio;
    const frames = [];

    times(frameCountTotal, n => {
        const x = n % frameCountX;
        const y = Math.floor(n / frameCountX);

        frames.push({
            left: `-${width * x}px`,
            top: `-${height * y}px`,
        });
    });

    return frames;
}


export function preloadImages(imageUrls = []) {
    let success = true;
    forEach(imageUrls, url => {
        try {
            let img = new Image();
            img.src = url;
        } catch(err) {
            console.error(err);
            success = false;
            return false;
        }
    });

    return success;
}
