import React from "react";
import styles from "./BIProductDisclosures.scss";
import classnames from "classnames";

export default function BiProductDisclosures(props) {
    return (
        <div
            data-testid="bi_product_disclosures_component"
            className={classnames(styles.root, "full-width full-height flex flex-column text-notice")}
        >
            <div className="flex full-width column-to-row-sm">
                <div className={styles.item}>
                    <b>IMPORTANT SAFETY INFORMATION</b>: Following the use of HEARTGARD® Plus (ivermectin/pyrantel), digestive and neurologic side effects and itching have been reported. All dogs should be tested for heartworm infection before starting a preventive program.
                    For more information, including full prescribing information, talk to your veterinarian, call 888-637-4251,
                    <span> or </span>
                    <a
                        href="https://www.heartgardclinic.com/"
                        target="_blank"
                        className="text-primary"
                    >visit HEARTGARDClinic.com</a>.
                </div>
                <div className={styles.item}>
                    <b className="text-uppercase">Important Safety Information</b>: <i>NexGard&reg;</i> (afoxolaner) and <span style={{ textWrap: "nowrap" }}><i>NexGard&reg;</i> PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets) </span> are for use in dogs only. NG Combo<span style={{ textWrap: "nowrap" }}> (esafoxolaner, eprinomectin, and praziquantel topical solution) </span> is for use in cats only. Use <i>NexGard&reg;</i> Brand Products with caution in dogs or cats with a history of seizures or neurologic disorders.
                    For more information, including full prescribing information, talk to your veterinarian, call 888-637-4251,
                    <span> or </span>
                    <a
                        href="https://www.nexgardclinic.com/"
                        target="_blank"
                        className="text-primary"
                    >visit NexGardClinic.com</a>.
                </div>
            </div>
            <div className="full-width margin-top-sm">
                <b>
                    HEARTGARD® Plus (ivermectin/pyrantel) and the Dog &amp; Hand logo® are registered trademarks of Boehringer Ingelheim Animal Health USA Inc.
                    <i> NexGard</i>® (afoxolaner) is a registered trademark of Boehringer Ingelheim Animal Health France, used under license.
                    All other trademarks are the property of their respective owner.
                    ©2023 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA.
                    All rights reserved.
                    US-PET-0091-2024
                </b>
            </div>
        </div>
    );
}
