import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericGame.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import map from "lodash/map";
import orderBy from "lodash/orderBy";
import round from "lodash/round";
import GenericGameLeaderboard from "./GenericGameLeaderboard";
import GenericLeaderboardTable from "components/games/pages/ClinicGamePage/GenericLeaderboardTable";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import { addCommasToNumber } from "utils/numeric";
import { userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import PointsLegend from "components/games/widgets/PointsLegend";

const getSumOfFieldFromList = (list, fieldSelector) => {
    let sum = 0;

    list.map(item => {
        sum += get(item, fieldSelector, 0);
    });

    return sum;
};

function GenericGame({
    animate,
    bannerAd,
    bannerAdAltText,
    clinicId,
    game,
    isProvider,
    Leaderboard,
    LegalWording,
    pointValues = {},
    printing,
    snapshot,
    style = {},
    usePoints,
    onlyPaid,
}) {
    const [gameLoaded, setGameLoaded] = useState(false);
    const [teams, setTeams] = useState([]);
    const showAllPoints = isProvider || snapshot;

    const doseCountSourceField = useMemo(() => {
        if (onlyPaid) {
            return "TotalPaidDoses";
        }
        return "TotalReportDoses";
    }, [onlyPaid]);

    const dosesToPlace = (doses, place) => {
        return `${addCommasToNumber(doses)} doses behind ${place} Place.`;
    };

    const getToWinHover = (place, dosesNeeded=[]) => {
        switch (place) {
            case 1:
                return ["You're in the lead!"];
            case 2:
                return [dosesToPlace(dosesNeeded.ToFirstPlace, "First")];
            case 3:
                return [
                    dosesToPlace(dosesNeeded.ToFirstPlace, "First"),
                    dosesToPlace(dosesNeeded.ToSecondPlace, "Second"),
                ];
            default:
                return [
                    dosesToPlace(dosesNeeded.ToFirstPlace, "First"),
                    dosesToPlace(dosesNeeded.ToSecondPlace, "Second"),
                    dosesToPlace(dosesNeeded.ToThirdPlace, "Third"),
                ];
        }
    };

    const clinicsList = useMemo(() => {
        if (game) {
            return orderBy(map(game.details, c => {
                const clinicDetails = find(game.data.Clinics, { ClinicId: c.ClinicId }) || {};

                // Calculate the "Nexgard Plus" doses based on clinic data
                const filterNexgardPlusProductBrands = (list=[]) => filter(list, { ProductBrandId: 331, Name: "Nexgard Plus" });
                const currentDoses = clinicDetails.CurrentPeriod?.[doseCountSourceField] || 0;
                const currentPoints = clinicDetails.CurrentPeriod?.TotalReportPoints || 0;
                const previousDoses = clinicDetails.PreviousPeriod?.[doseCountSourceField] || 0;
                const previousPoints = clinicDetails.PreviousPeriod?.TotalReportPoints || 0;
                const dosesToGo = Math.ceil(previousDoses - currentDoses);
                const pointsToGo = Math.ceil(previousPoints - currentPoints);
                const currentPeriodNgPlusProductBrands = filterNexgardPlusProductBrands(clinicDetails.CurrentPeriod?.ProductBrands);
                const previousPeriodNgPlusProductBrands = filterNexgardPlusProductBrands(clinicDetails.PreviousPeriod?.ProductBrands);
                return {
                    ...c,
                    clinicId: c.ClinicId,
                    ClinicName: clinicDetails.ClinicName,
                    name: isProvider ? clinicDetails.ClinicName : c.ClinicAlias,
                    currentDoses,
                    currentPoints,
                    previousDoses,
                    previousPoints,
                    currentNgPlusDoses: getSumOfFieldFromList(currentPeriodNgPlusProductBrands, "ReportDoses"),
                    currentNgPlusPoints: getSumOfFieldFromList(currentPeriodNgPlusProductBrands, "ReportPoints"),
                    previousNgPlusDoses: getSumOfFieldFromList(previousPeriodNgPlusProductBrands, "ReportDoses"),
                    previousNgPlusPoints: getSumOfFieldFromList(previousPeriodNgPlusProductBrands, "ReportPoints"),
                    dosesToGo: dosesToGo > 0 ? dosesToGo : "--",
                    pointsToGo: pointsToGo > 0 ? pointsToGo : "--",
                    toWin: round(clinicDetails.DosesNeeded?.ToFirstPlace) || "--",
                    toWinHover: c.Doses ? getToWinHover(c.Place, clinicDetails.DosesNeeded) : ["You have not sold any doses yet."],
                };
            }), "Place");
        }
        return [];
    }, [game, isProvider]);

    useEffect(() => {
        if (game?.details && clinicsList.length) {
            setTeams(map(game.details, team => {
                const clinicDetails = find(game.data.Clinics, { ClinicId: team.ClinicId }) || {};
                const eventTotalsClinicData = find(game.data.ClinicEventTotals, { ClinicId: team.ClinicId }) || { EventTotals: [] };
                return {
                    ...team,
                    eventTotals: eventTotalsClinicData.EventTotals,
                    name: isProvider ? clinicDetails.ClinicName : team.ClinicAlias,
                }
            }));
            setGameLoaded(true);
        }
    }, [game, isProvider, clinicsList]);

    const gameAnimation = useMemo(() => {
        if (gameLoaded) {
            const gameKey = clinicId ? `game_animation_${game.greenlineGameId}_clinic_${clinicId}` :
                `game_animation_${game.greenlineGameId}_day_${game.daysInto}`;
            if (!!Leaderboard) {
                return (
                    <Leaderboard
                        key={gameKey}
                        animate={animate}
                        currentClinicId={clinicId}
                        currentEvent={game?.data?.GameEvent}
                        data={teams}
                        daysRemaining={game?.daysRemaining}
                        printing={printing}
                        showAllPoints={showAllPoints}
                        snapshot={snapshot}
                    />
                );
            } else {
                return (
                    <GenericGameLeaderboard
                        key={gameKey}
                        animate={animate}
                        animationOptions={[]}
                        backgroundImage=""
                        backgroundImageAlt="Background Image needed"
                        currentClinicId={clinicId}
                        data={teams}
                        printing={printing}
                        showAllPoints={showAllPoints}
                        snapshot={snapshot}
                    />
                );
            }
        } else {
            return <Loader />;
        }
    }, [gameLoaded, animate, teams]);

    return (
        <div className={styles.root}>
            <div className={styles.bannerAd}>
                <Image
                    src={bannerAd ? bannerAd : null}
                    alt={bannerAdAltText ? bannerAdAltText : null}
                />
            </div>
            <div className={classnames(styles.animationWrapper, {
                [styles.snapshot]: snapshot,
            })}>
                <div className={styles.animation}>
                    {gameAnimation}
                </div>
            </div>
            {game?.data?.Multipliers && (
                <PointsLegend legend={game.data.Multipliers}/>
            )}
	        {!!LegalWording && (
                <div className={styles.legalWording}>
                    <LegalWording />
                </div>
            )}
            <div className={styles.rankingsTable}>
                <GenericLeaderboardTable
                    clinicId={clinicId}
                    clinics={clinicsList}
                    game={game}
                    isProvider={isProvider}
                    snapshot={snapshot}
                    showAllPoints={showAllPoints}
                    usePoints={usePoints}
                />
            </div>
        </div>
    );
}

GenericGame.propTypes = {
    animate: PropTypes.bool,
    clinicId: PropTypes.number,
    game: PropTypes.object.isRequired,
    isProvider: PropTypes.bool,
    Leaderboard: PropTypes.node,
	LegalWording: PropTypes.node,
    pointValues: PropTypes.object,
    printing: PropTypes.bool,
    snapshot: PropTypes.bool,
    style: PropTypes.object,
    usePoints: PropTypes.bool,
    onlyPaid: PropTypes.bool,
};

export default connect(
    (state) => {
        const userProfile = state.user.userProfile;
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        return {
            isProvider,
        }
    },
)(GenericGame);
