import React from "react";
import PropTypes from "prop-types";
import PuppyBowlSetup from "./PuppyBowlSetup";
import PoolPartySetup from "./PoolPartySetup";
import OnGardSetup from "./OnGardSetup";
import GameTaskSetup from "./GameTaskSetup";
import { GAME_TYPES } from "constants/Games/GameConstants";

export default function GameTaskEditor(props) {
    switch (props.gameType) {
        case GAME_TYPES.ON_GARD:
            return <OnGardSetup data={props.data} onChange={props.onChange} />
        case GAME_TYPES.PUPPY_BOWL:
            return <PuppyBowlSetup data={props.data} onChange={props.onChange} />
        default:
            return <GameTaskSetup data={props.data} onChange={props.onChange} />
    }
}

GameTaskEditor.propTypes = {
    data: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    gameType: PropTypes.string.isRequired,
}
