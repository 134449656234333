import React, { useEffect } from "react";
import PropTypes from "prop-types";

export default function PuppyBowlLeaderboard(props) {
    const {
        animate = false,
        currentClinicId,
        data,
        pathToSrcFiles = "puppy_bowl_leaderboard/",
        snapshot = false,
    } = props;

    const loadHtml = () => ({
        __html: `
            <iframe
                id="puppy-bowl-leaderboard-iframe"
                src="${pathToSrcFiles}index.html"
                width="100%"
                height="100%"
                style="border-width: 0; padding: 0;"
                
            />
        `
    });

    const shouldAnimate = !snapshot || (snapshot && animate);

    const waitForLeaderboardHtmlToLoad = () => {
        const intervalId = setInterval(() => {
            // 20240829 !!window.isPuppyBowlLeaderboard was ALWAYS truthy, so I changed it to !!window.isPuppyBowlLeaderboardReadyToLoad            
            if (!!window.isPuppyBowlLeaderboardReadyToLoad) {
                clearInterval(intervalId);
                // Js has loaded
                new window.PuppyBowlLeaderboard(data, currentClinicId, shouldAnimate, snapshot);
            }
        }, 200);
    };

    useEffect(() => {
        waitForLeaderboardHtmlToLoad();
        return () => {
            window.PuppyBowlLeaderboard = null;
            window.isPuppyBowlLeaderboardReadyToLoad = null;
        }
    }, []);

    return (
        <div dangerouslySetInnerHTML={loadHtml()} />
    );
}

PuppyBowlLeaderboard.propTypes = {
    data: PropTypes.arrayOf(PropTypes.shape({
        ClinicId: PropTypes.number,
        ClinicAlias: PropTypes.string,
        TotalPuppyBowlPoints: PropTypes.number,
        Place: PropTypes.number,
        PercentGrowth: PropTypes.number,
    })),
    currentClinicId: PropTypes.number,
    pathToSrcFiles: PropTypes.string,
    snapshot: PropTypes.bool,
    animate: PropTypes.bool,
};
