import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import filter from "lodash/filter";
import map from "lodash/map";
import isString from "lodash/isString";
import GenericAnimation from "./GenericAnimation";
import { preloadImages } from "utils/animation/animationHelpers";

export default function MultipleAnimations({
	animations,
	onAnimationCompleted = () => undefined,
	onAnimationStarted = () => undefined,
	onBeforeAnimation = () => undefined,
	repeat = false,
}) {
	const [currentAnimation, setCurrentAnimation] = useState(0);
	const [restartCounter, setRestartCounter] = useState(0);
	const [imagesPreloaded, setImagesPreloaded] = useState(false);

	const handleAnimationStarted = () => {
		onBeforeAnimation(currentAnimation);
		onAnimationStarted();
	};

	const handleAnimationComplete = () => {
		onAnimationCompleted(currentAnimation);
		if (currentAnimation + 1 < animations.length) {
			setCurrentAnimation(currentAnimation + 1);
		} else if (repeat) {
			onBeforeAnimation(0);
			setCurrentAnimation(0);
			setRestartCounter(restartCounter + 1);
		}
	};


	useEffect(() => {
		if (animations?.length && !imagesPreloaded) {
			const imageUrls = map(filter(animations, ani => isString(ani.image)), animation => animation.image);
			const isSuccess = preloadImages(imageUrls);
			setImagesPreloaded(isSuccess);
		}
	}, [animations, imagesPreloaded]);


	if (!animations?.[currentAnimation]) {
		return null;
	}

	return (
		<GenericAnimation
			key={`run_${restartCounter}_animation_${currentAnimation}`}
			animation={animations[currentAnimation]}
			onAnimationStarted={handleAnimationStarted}
			onAnimationComplete={handleAnimationComplete}
		/>
	);
}

MultipleAnimations.propTypes = {
	animations: PropTypes.arrayOf(PropTypes.shape({
		altText: PropTypes.string.isRequired,
		animationDuration: PropTypes.number, // How long should the whole animation play (Repeat animation for X seconds)
		duration: PropTypes.number.isRequired, // How long does it take for the animation to play once
		frames: PropTypes.arrayOf(PropTypes.shape({
			image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]), // If image is different then the default
			duration: PropTypes.number, // If duration should not be an equal part of the animation
			top: PropTypes.string,
			right: PropTypes.string,
			bottom: PropTypes.string,
			left: PropTypes.string,
		})).isRequired,
		height: PropTypes.string.isRequired,
		image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]).isRequired,
		imageFrame: PropTypes.object,
		imageStyle: PropTypes.object,
		isAnimated: PropTypes.bool,
		isTransparent: PropTypes.bool,
		lastFrame: PropTypes.number,
		left: PropTypes.string,
		top: PropTypes.string,
		width: PropTypes.string.isRequired,
	})).isRequired,
	onAnimationCompleted: PropTypes.func,
	onAnimationStarted: PropTypes.func,
	onBeforeAnimation: PropTypes.func,
	repeat: PropTypes.bool,
};
