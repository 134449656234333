export const charLimit = (text, maxLength, isHTML) => {
    if ((text || "").length > maxLength) {
        if(!isHTML) {
            return `${text.substring(0, maxLength - 1)}…`;
        }

        // isHTML
        let mention = text.indexOf("class=\"mention\"");
        let totalMaxLength = maxLength;
        if(mention > -1 && mention < maxLength) {
            // there is a mention in the first 200 characters, add more to get past the crazy HTML
            totalMaxLength = maxLength + 275;
        }

        // Return all if it's less than max
        if(totalMaxLength >= text.length) {
            return text;
        }

        // If there's not a </p> tag, just return the max
        let pIndex = text.indexOf("</p>");
        if(pIndex < 0) {
            return `${text.substring(0, totalMaxLength - 1)}…`;
        }

        // Loop through looking for all </p> and stop once it's past totalMaxLength
        let lastIndex = pIndex;
        while(lastIndex < totalMaxLength ) {
            pIndex = text.substring(lastIndex+5).indexOf("</p>");
            if(pIndex < 0) {
                // No more matches
                break;
            }

            // Update the last index
            lastIndex = lastIndex + 5 + pIndex;
        }

        if(lastIndex > totalMaxLength + 20) {
            return `${text.substring(0, totalMaxLength - 1)}…</p>`;
        } else {
            return `${text.substring(0, lastIndex)}…</p>`;
        }
    }

    return text;
}
