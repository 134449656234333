import React, { useState } from "react";
import Modal from "components/common/Modal";
import styles from "./GrandSlamLegalWording.scss";
import GrandSlamImportantSafetyInformation from "components/common/ImportantSafetyInformation/GrandSlamImportantSafetyInformation";

export function GrandSlamLeaderboardLegalWording(props) {
    const [showSafetyInfo, setShowSafetyInfo] = useState(false);
    const handleShowSafetyInformation = () => {
        setShowSafetyInfo(true);
    }

	return (
        <div className={styles.leaderBoardLegalWording}>
            <div className="padding-bottom-sm">The number of doses shown above are doses of HEARTGARD® Plus (ivermectin/pyrantel), <i>NexGard</i>® (afoxolaner), <i>NexGard</i>® PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets), and <i>NexGard</i>® COMBO (esafoxolaner, eprinomectin, and praziquantel topical solution) sold so far in this game.</div>
            <div className="padding-bottom-sm">To view current or previous doses broken out by product, click the underlined numbers in the leaderboard!</div>
            <div className="padding-bottom-sm">
                The percentage compares doses dispensed (points earned) so far in this game versus doses dispensed (points earned) in the timeframe the previous sixty days in 2024 (July and August).
                "VLSD" stands for Versus Last Sixty Days.
            </div>
            <div className="padding-bottom-sm">
                <a
                    className="text-primary"
                    href="https://cdn.greenlinepet.com/games/assets/termsandconditions/2024GrandSlamTAndC.pdf"
                    target="_blank"
                >
                    View terms and conditions.
                </a>
            </div>
            <div className="padding-bottom-sm">
                <a
                    className="text-primary"
                    onClick={() => setShowSafetyInfo(true)}
                >
                    Important Safety Information
                </a>
            </div>
            <div className={styles.fontWeight400}>
                <div className="padding-bottom-sm">
                    HEARTGARD®, is a registered trademark of Boehringer Ingelheim Animal Health USA Inc. <i>NexGard</i>® is a registered trademark and <i>NexGard</i> COMBO™ is a trademark of Boehringer Ingelheim Animal Health France, used under license.
                </div>
                <div>
                    All other trademarks are the property of their respective owner. ©2024 Boehringer Ingelheim Animal Health USA Inc., Duluth, GA. All rights reserved. US-PET-0654-2024-C
                </div>
            </div>
            <Modal
                modalTitle="Important Safety Information"
                show={showSafetyInfo}
                onClose={() => setShowSafetyInfo(false)}
                medium
            >
                <GrandSlamImportantSafetyInformation />
            </Modal>
        </div>
    )
}

export default function GrandSlamLegalWording(props) {
    const [showSafetyInfo, setShowSafetyInfo] = useState(false);
	const handleShowSafetyInformation = () => {
        setShowSafetyInfo(true);
    }
	return (
		<div className={styles.root}>
            <div className={styles.meds}>
	            Dispense doses to win prizes! It's time to score big! Click below to view the leaderboard.
            </div>
            <div className={styles.viewSafetyLink} onClick={handleShowSafetyInformation}>
	            Click here to view the Important Safety Information for <br/>HEARTGARD® Plus (ivermectin/pyrantel), <i>NexGard</i>® (afoxolaner)<br/>
                <i>NexGard</i>® PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets), and <br/>
                <i>NexGard</i>® COMBO (esafoxolaner, eprinomectin, and praziquantel topical solution)
            </div>
			<Modal
                modalTitle="Important Safety Information"
                show={showSafetyInfo}
                onClose={() => setShowSafetyInfo(false)}
                medium
            >
                <GrandSlamImportantSafetyInformation />
            </Modal>
        </div>
	)
}
