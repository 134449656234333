import React from "react";

const NEXGARD_PLUS_URL = "https://www.nexgardplus.com";
const NEXGARD_COMBO_URL = "https://www.nexgardcomboclinic.com"
const NEXGARD_URL = "https://www.nexgardclinic.com";
const HEARTGARD_CLINIC_URL = "https://www.heartgardclinic.com";

const NEXGARD_PLUS_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PLUS_PI_2023.pdf";
const NEXGARD_COMBO_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_COMBO_PI_2023.pdf";
const NEXGARD_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/NexGard_PI_2020.pdf";
const HEARTGARD_PLUS_INFORMATION_LINK = "https://cdn.greenlinepet.com/games/assets/prescribinginfo/HGPlus_PI_2022.pdf";

export default function GrandSlamImportantSafetyInformation() {
    return (
        <div data-testid="important_safety_information_component">
            <div>
                <div className="margin-sm padding-bottom-md border-bottom">
                    <b>IMPORTANT SAFETY INFORMATION:</b> <i>NexGard</i>® COMBO (esafoxolaner, eprinomectin, and praziquantel topical solution) is for topical use only in cats.
                    Use with caution in cats with a history of seizures or neurologic disorders.
                    The most frequently reported adverse reactions include vomiting, application site reactions, lethargy, and anorexia.
                    If ingested, hypersalivation may occur.
                    Avoid direct contact with application site until visibly dry.
                    For more information, see
                    <a className="pointer text-primary" href={NEXGARD_COMBO_INFORMATION_LINK} target="_blank"> full prescribing information </a>
                    or visit
                    <a className="pointer text-primary" href={NEXGARD_COMBO_URL} target="_blank"> NexGardCOMBOClinic.com</a>.
                </div>
                <div className="margin-sm padding-bottom-md border-bottom">
                    <b>IMPORTANT SAFETY INFORMATION:</b> <i>NexGard</i>® PLUS (afoxolaner, moxidectin, and pyrantel chewable tablets), is for use in dogs only.
                    The most frequently reported adverse reactions include diarrhea, vomiting, lethargy, and itching.
                    Use with caution in dogs with a history of seizures or neurologic disorders.
                    Dogs should be tested for existing heartworm infection prior to starting a preventive.
                    For more information or
                    <a className="pointer text-primary" href={NEXGARD_PLUS_INFORMATION_LINK} target="_blank"> full prescribing information</a>
                    , speak with your vet, call
                    <a className="text-primary pointer" href="tel:18886374251"> (888)637-4251 </a>
                    or visit
                    <a className="pointer text-primary" href={NEXGARD_PLUS_URL} target="_blank"> NexGardPLUS.com</a>.
                </div>
                <div className="margin-sm padding-bottom-md border-bottom">
                    <b>IMPORTANT SAFETY INFORMATION:</b> <i>NexGard</i>® (afoxolaner) is for use in dogs only.
                    Use with caution in dogs with a history of seizures or neurologic disorders.
                    For more information or
                    <a className="pointer text-primary" href={NEXGARD_INFORMATION_LINK} target="_blank"> full prescribing information</a>
                    , speak with your vet, call
                    <a className="text-primary pointer" href="tel:18886374251"> (888)637-4251 </a>
                    or visit
                    <a className="pointer text-primary" href={NEXGARD_URL} target="_blank"> NexGardClinic.com</a>.
                </div>
                <div className="margin-sm padding-bottom-md">
                    <b>IMPORTANT SAFETY INFORMATION:</b> Following the use of HEARTGARD® Plus (ivermectin/pyrantel) in dogs, digestive and neurologic side effects and itching have been reported.
                    All dogs should be tested for heartworm infection before starting a preventive program.
                    For more information, see
                    <a className="pointer text-primary" href={HEARTGARD_PLUS_INFORMATION_LINK} target="_blank"> full prescribing information </a>
                    or visit
                    <a className="pointer text-primary" href={HEARTGARD_CLINIC_URL} target="_blank"> HEARTGARDClinic.com</a>.
                </div>
            </div>
        </div>
    );
}
