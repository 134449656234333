import React, {
    // Comment out for generating the email scripts
    useContext,
    useMemo,
    useRef,
    useState
} from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericGameLeaderboard.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import last from "lodash/last";
import map from "lodash/map";
import sortBy from "lodash/sortBy";
import MultipleAnimations from "./MultipleAnimations";
import { addCommasToNumber } from "utils/numeric";

// Comment out for generating the email scripts
import Confetti from "react-confetti";
import { windowSizeContext } from "AppRoot";

export default function GenericGameLeaderboard({
	   animate = false,
	   animationOptions,
	   backgroundImage,
	   backgroundImageAlt,
	   currentClinicId,
	   data = [],
	   getRowAnimationIndex,
	   goal = 100,
	   highlightTextColor = "#69FF8CFF",
	   onAnimationComplete,
	   points = false,
	   printing = false,
	   rowStyle = {},
	   rowsContainerStyle = {},
	   scores = ["20%", "40%", "60%", "80%", "100%", "120%", "140%", "160%", "180%", "200%"],
	   scoresStyle = {},
	   showAllPoints = false,
	   showConfetti = false,
	   textColor = "white",
   }) {
	// Comment out for generating the email scripts
	const context = useContext(windowSizeContext) || {};
	const [showingConfetti, setShowingConfetti] = useState(false);
	const [showPoints, setShowPoints] = useState(printing || !animate);
	const sortedData = sortBy(data, "Place");
	const topTenData = sortedData.slice(0, 10);
	const currentClinicData = !!currentClinicId ? find(sortedData, { ClinicId: currentClinicId }) : null;
	const currentClinicAfter10 = !!currentClinicData && currentClinicData.Place > 10;

	const backgroundRef = useRef();

	const handleAnimationCompleted = (completedAnimation) => {
		if (completedAnimation === 0) {
			const clinicDetails = find(topTenData, { ClinicId: currentClinicId });
			if ((goal && ((clinicDetails?.PercentGrowth + 100) >= goal)) || showConfetti) {
				setShowingConfetti(true);
			}
		}
		setShowPoints(true);
		if (onAnimationComplete) {
			onAnimationComplete(completedAnimation);
		}
	};

	if (!data.length) return null;

	const renderTeamDetails = (row, rowWidthPercentage, canViewPoints=false) => {
		let percentGrowthString = "0%";
		if (row.PercentGrowth !== null && !isNaN(row.PercentGrowth)) {
			percentGrowthString = `${Math.round(100 + row.PercentGrowth).toFixed(0)}%`;
		} else {
			percentGrowthString = "0%";
		}
		const firstBreak = rowWidthPercentage < 8;
		const secondBreak = rowWidthPercentage >= 8 && rowWidthPercentage < 40;
		const thirdBreak = rowWidthPercentage >= 40 && rowWidthPercentage < 75;

		return (
			<div className={styles.rowContent}>
				<div
					className={"flex align-center spaced-content"}
					style={firstBreak ? {
						position: "absolute",
						right: "-.5em",
						transform: "translateX(100%)",
					} : {}}
				>
					<div
						className={classnames(styles.ranking, "flex-row")}
						style={firstBreak ? { paddingLeft: "1em" } : {}}
					>
						<span className={styles.poundSign}>#</span>
						{row.Place}
					</div>
					<div
						className={"flex align-center spaced-content"}
						style={secondBreak ? {
							position: "absolute",
							right: "-2em",
							transform: "translateX(100%)",
						} : {}}
					>
						<div className={styles.teamName}>
							{row.name}
						</div>

						<div
							className={classnames(styles.teamPoints, {
								[styles.showPoints]: showPoints,
							})}
							style={thirdBreak ? {
								right: "-5em",
								position: "absolute",
								transform: "translateX(100%)",
							} : {}}
						>
							{canViewPoints ? (
								<>
									{points ? (
										<span>{addCommasToNumber(row.Points) || 0} points, {percentGrowthString} VLSD</span>
									) : (
										<span>{addCommasToNumber(row.Doses) || 0} doses, {percentGrowthString} VLSD</span>
									)}
								</>
							) : (
								<span>{percentGrowthString} VLSD</span>
							)}
						</div>
					</div>
				</div>
			</div>
		)
	};

	const getRowAnimations = (row, index) => {
		if (!!getRowAnimationIndex) {
			// This is used to get an animation based on a certain piece of data. It returns the index of the animation.
			const index = getRowAnimationIndex(row);
			if (!animationOptions?.[index]) {
				console.error("The index for this animation doesn't exist");
				return animationOptions[0];
			}
			return animationOptions[index];
		} else {
			// This goes through all animation options and then repeats
			const evenlyDivisible = filter(animationOptions, (a, i) => {
				return (index + 1) % (i + 1) === 0;
			});
			return last(evenlyDivisible);
		}
	};

	const renderRow = (row, index) => {
		const isCurrentTeam = row.ClinicId === currentClinicId;
		const pointsVisible = isCurrentTeam || showAllPoints;
		let rowWidthPercentage = (100 + (row.PercentGrowth || -100)) / 2;
		if (rowWidthPercentage >= 100) {
			rowWidthPercentage = 100;
		}

		if (row.Place <= 10) {
			// TODO:
			// sprite = applyCustomColors(sprite, ranking);
		}

		const filteredAnimations = useMemo(() => {
			const rowAnimations = getRowAnimations(row, index);
			return filter(map(rowAnimations.animations, a => {
				let canView = a.canView
				if (!!a.getCanView) {
					canView = a.getCanView(row);
				}
				return {
					...a,
					canView,
					image: a.getImage ? a.getImage(index) : row.image,
					isAnimated: !printing,
					isTransparent: a.getIsTransparent(row),
					altText: a.getAltText(row) || "Unknown",
				};
			}), "canView");
		}, [animationOptions, row]);

		return (
			<div
				id={`content_${row.Place}`}
				className={classnames(styles.rowAnimationContainer, "flex-align-center", {
					[styles.animated]: !printing && animate,
					[styles.rowCurrentTeam]: isCurrentTeam,
				})}
				style={{
					maxWidth: `${rowWidthPercentage}%`,
					color: (isCurrentTeam && highlightTextColor)  ? highlightTextColor : textColor,
				}}
			>
				{renderTeamDetails(row, rowWidthPercentage, pointsVisible)}
				<div className={styles.progressBar} />
				<MultipleAnimations
					key={`Animations_${row.clinicId}`}
					animations={filteredAnimations}
					onAnimationCompleted={handleAnimationCompleted}
				/>
			</div>
		);
	};

	return (
		<div
			ref={backgroundRef}
			id="background-container"
			className={styles.backgroundContainer}
		>
			<div className={styles.backgroundSizer}>
				<div className={styles.backgroundImageSizer} >
					<img
						className={styles.backgroundImage}
						src={backgroundImage}
						alt={backgroundImageAlt}
					/>
					<div
						id="background-content"
						className={styles.backgroundContent}
					>
						<div className={styles.rows} style={rowsContainerStyle}>
							{map(topTenData, (row, index) => (
								<div
									key={row.ClinicId}
									id={row.Place}
									className={classnames(styles.row, {
										[styles.rowSmaller]: currentClinicAfter10,
									})}
									style={rowStyle}
								>
									{renderRow(row, index)}
								</div>
							))}
							{/* Conditionally shown if the current clinic is not in the top 10 */}
							{currentClinicAfter10 && (
								<div
									id="current_clinic_rank_above_10"
									className={classnames(styles.row, {
										[styles.rankAbove10Hidden]: !currentClinicAfter10,
										[styles.printing]: printing,
									})}
								>
									{renderRow(currentClinicData, 10)}
								</div>
							)}
						</div>
					</div>
				</div>
			</div>
			<div className={styles.scoreContainer}>
				<div
					style={scoresStyle}
					className={classnames(styles.scores)}
				>
					{map(scores, (score, index) => (
                        <span key={`score_${index}`}>{score}</span>
                    ))}
				</div>
			</div>
			{/* Comment out for generating the email scripts*/}
			{showingConfetti && (
				<Confetti
					width={context.windowWidth}
					drawShape={ctx => {
						ctx.clearRect(0,0, ctx.width, ctx.height);
						ctx.font = "30px FontAwesome";
						ctx.fillText('\uF5D7',0,0);
					}}
					numberOfPieces={600}
					recycle={false}
					tweenDuration={20000}
				/>
			)}
		</div>
	);
}

GenericGameLeaderboard.propTypes = {
	animate: PropTypes.bool,
	animationOptions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
	backgroundImage: PropTypes.string.isRequired,
	backgroundImageAlt: PropTypes.string.isRequired,
	borderColor: PropTypes.string,
	currentClinicId: PropTypes.number,
	data: PropTypes.array,
	getRowAnimationIndex: PropTypes.func, // Used to figure out which animation should be displayed for each row.
	goal: PropTypes.number,
	highlightTextColor: PropTypes.string,
	lineColor: PropTypes.string,
	onAnimationComplete: PropTypes.func,
	points: PropTypes.bool, // If this is true, the game uses points otherwise, it uses doses.
	printing: PropTypes.bool,
	rowStyle: PropTypes.object,
	rowsContainerStyle: PropTypes.object,
	scoresStyle: PropTypes.object,
	showAllPoints: PropTypes.bool,
	showConfetti: PropTypes.bool,
	textColor: PropTypes.string,
};
