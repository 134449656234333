import React, { useMemo } from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import get from "lodash/get";
import toLower from "lodash/toLower";
import GenericGameCard from "components/games/elements/GenericGameCard";
import {GoldenSummerLegalWording } from "../LegalWording";
import {GoldenSummerLeaderboardLegalWording} from "../LegalWording/GoldenSummerLegalWording";

const KittyCycle = require("./images/kitty_cycle.svg");
const KittyCycleFail = require("./images/kitty_cycle_fail.svg");
const KittyPoleVault = require("./images/kitty_pole_vault.svg");
const KittyPoleVaultSuccess = require("./images/kitty_pole_vault_success.svg");
const KittyPoleVaultFail = require("./images/kitty_pole_vault_fail.svg");
const KittyPoleVaultFailFinal = require("./images/kitty_pole_vault_fail_final.svg");
const KittyRun = require("./images/kitty_run.svg");
const KittyRunFail = require("./images/kitty_run_fail.svg");
const KittySwim = require("./images/kitty_swim.svg");
const KittySwimFail = require("./images/kitty_swim_fail.svg");
const KittyWinning = require("./images/kitty_win.svg");

const PuppyCycle = require("./images/puppy_cycle.svg");
const PuppyCycleFail = require("./images/puppy_cycle_fail.svg");
const PuppyPoleVault = require("./images/puppy_pole_vault.svg");
const PuppyPoleVaultSuccess = require("./images/puppy_pole_vault_success.svg");
const PuppyPoleVaultFail = require("./images/puppy_pole_vault_fail.svg");
const PuppyPoleVaultFailFinal = require("./images/puppy_pole_vault_fail_final.svg");
const PuppyRun = require("./images/puppy_run.svg");
const PuppyRunFail = require("./images/puppy_run_fail.svg");
const PuppySwim = require("./images/puppy_swim.svg");
const PuppySwimFail = require("./images/puppy_swim_fail.svg");
const PuppyWinning = require("./images/puppy_win.svg");

const Pool = require("./images/pool_bg_long.svg");
const WoodFloor = require("./images/cycling_bg_long.png");
const smallImage = "https://cdn.greenlinepet.com/games/assets/GoldenGames/GoldenGamesEndzoneBanner320x50_r1v1.jpg";

const SECOND = 1000;
const MINUTE = SECOND * 60;

// Length of the entire animation
const animationDuration = 5 * SECOND;

// How it takes to run through the animation once
const duration_2 = SECOND / 3; // Animation with 2 frames
const duration_4 = duration_2 * 2; // Animation with 4 frames
const duration_6 = duration_2 * 3; // Animation with 6 frames
const duration_8 = duration_2 * 4; // Animation with 8 frames

const FRAME_1 = [{
    top: "0",
    left: "0",
}];

const FRAMES_2 = [{
    top: "0",
    left: "0",
}, {
    top: "0",
    left: "-95%",
}];

const FRAMES_4 = [
    ...FRAMES_2,
    // {
    //     top: "0",
    //     left: "-190%",
    // }, {
    //     top: "0",
    //     left: "-280%",
    // }
];

const FRAMES_8 = [{
    top: "0",
    left: "0",
}, {
    top: "0",
    left: "-88.9%",
}, {
    top: "0",
    left: "-177.8%",
}, {
    top: "0",
    left: "-266.6%",
}, {
    top: "0",
    left: "-355.5%",
}, {
    top: "0",
    left: "-444.4%",
}, {
    top: "0",
    left: "-533.3%",
}, {
    top: "0",
    left: "-622.2%",
}];

const HEIGHT = "10em";

export default function GoldenSummerGamesCard(props) {
    const handleHasScored = (gameDetails, snapshots) => {
        let today;
        let yesterday;
        if (snapshots?.length === 1) {
            // Handle when it's the first day, all 0s for Yesterday
            yesterday = snapshots[0];
            today = find(gameDetails.data.Clinics, clinic => clinic.ClinicId === props.clinicId);
        } else if (snapshots?.length) {
            yesterday = find(snapshots[0].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
            today = find(snapshots[1].data.Clinics, clinic => clinic.ClinicId === props.clinicId);
        } else {
            return false;
        }
        const todayTotalDoses = get(today, "CurrentPeriod.TotalReportDoses", 0);
        const yesterdayTotalDoses = get(yesterday, "CurrentPeriod.TotalReportDoses", 0);
        const newDoses = todayTotalDoses - yesterdayTotalDoses;
        return newDoses > 0;
    };

    const handleHasNotScored = (gameDetails, snapshots) => {
        return !handleHasScored(gameDetails, snapshots);
    };

    const kitty_winning_frame = [{
        altText: "Kitty winning",
        animationDuration: animationDuration,
        canView: true,
        duration: duration_2,
        frames: FRAMES_2,
        getCanView: handleHasScored,
        height: HEIGHT,
        image: KittyWinning,
        isAnimated: true,
        lastFrame: 1,
        left: "30%",
        showScore: true,
        width: "14em",
    }];

    const puppy_winning_frame = [{
        altText: "Puppy winning",
        animationDuration: animationDuration,
        canView: true,
        duration: duration_2,
        frames: FRAMES_2,
        getCanView: handleHasScored,
        height: HEIGHT,
        image: PuppyWinning,
        isAnimated: true,
        lastFrame: 1,
        left: "30%",
        showScore: true,
        width: "14em",
    }];

	const animationOptions = [{
        id: "kitty_track",
        animal: "Kitty",
        animations: [{
			altText: "Kitty running",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_4,
            frames: FRAMES_4,
            height: HEIGHT,
	        image: KittyRun,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        },
        ...kitty_winning_frame,
        {
			altText: "Kitty running fail",
            animationDuration: animationDuration,
            duration: duration_4,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
	        image: KittyRunFail,
            isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }, {
        id: "kitty_cycling",
        animal: "Kitty",
        animations: [{
            altText: "Kitty cycling",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_2,
            frames: FRAMES_2,
            height: HEIGHT,
            image: KittyCycle,
            isAnimated: true,
            lastFrame: 2,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        },
        ...kitty_winning_frame,
        {
            altText: "Kitty cycling fail",
            animationDuration: animationDuration,
            duration: duration_2,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
	        image: KittyCycleFail,
            isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }, {
        id: "kitty_pole_vault",
        animal: "Kitty",
        animations: [{
            altText: "Kitty pole vault",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_4,
            frames: FRAMES_4,
            height: HEIGHT,
	        image: KittyPoleVault,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        }, {
            altText: "Kitty pole vault success",
            animationDuration: 1.33333 * SECOND,
            duration: duration_8,
            frames: FRAMES_8,
            getCanView: handleHasScored,
            height: "16em",
	        image: KittyPoleVaultSuccess,
            isAnimated: true,
            lastFrame: 7,
            left: "50%",
            right: "50%",
            showScore: false,
            top: "60%",
            width: "24em",
        },
        ...kitty_winning_frame,
        {
            altText: "Kitty pole vault fail",
            animationDuration: 1.33333 * SECOND,
            duration: duration_8,
            frames: FRAMES_8,
            getCanView: handleHasNotScored,
            height: "16em",
	        image: KittyPoleVaultFail,
            isAnimated: true,
            lastFrame: 7,
            left: "50%",
            right: "50%",
            showScore: false,
            top: "60%",
            width: "24em",
        }, {
            altText: "Kitty pole vault fail final",
            animationDuration: SECOND,
            duration: SECOND,
            frames: [...FRAME_1, ...FRAME_1],
            getCanView: handleHasNotScored,
            height: "16em",
	        image: KittyPoleVaultFailFinal,
            isAnimated: true,
            lastFrame: 0,
            left: "18%",
            showScore: true,
            width: "15em",
        }]
    }, {
        altText: "Kitty swimming",
        id: "kitty_swimming",
        animal: "Kitty",
        animations: [{
            animationDuration: animationDuration,
            canView: true,
            duration: duration_2,
            frames: FRAMES_2,
            height: HEIGHT,
            image: KittySwim,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        },
        ...kitty_winning_frame,
        {
            altText: "Kitty swimming fail",
            animationDuration: animationDuration,
            duration: duration_2,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
            image: KittySwimFail,
            isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }, {
        id: "puppy_track",
        animal: "Puppy",
        animations: [{
            altText: "Puppy track",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_4,
            frames: FRAMES_4,
            height: HEIGHT,
            image: PuppyRun,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            showScore: false,
            width: "14em",
        },
        ...puppy_winning_frame,
        {
            altText: "Puppy track fail",
            animationDuration: animationDuration,
            duration: duration_4,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
            image: PuppyRunFail,
            isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }, {
        id: "puppy_cycling",
        animal: "Puppy",
        animations: [{
            altText: "Puppy cycling",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_2,
            frames: FRAMES_2,
            height: HEIGHT,
            image: PuppyCycle,
            isAnimated: true,
            lastFrame: 1,
            left: "50%",
            right: "50%",
            showScore: false,
            width: "14em",
        },
        ...puppy_winning_frame,
        {
            altText: "Puppy cycling fail",
            animationDuration: animationDuration,
            duration: duration_2,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
            image: PuppyCycleFail,
            isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }, {
        id: "puppy_pole_vault",
        animal: "Puppy",
        animations: [{
            altText: "Puppy pole vault",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_4,
            frames: FRAMES_4,
            height: HEIGHT,
            image: PuppyPoleVault,
	        isAnimated: true,
            lastFrame: 1,
            left: "50%",
            showScore: false,
            width: "14em",
        }, {
            altText: "Puppy pole vault success",
            animationDuration: 1.33333 * SECOND,
            duration: duration_8,
            frames: FRAMES_8,
            getCanView: handleHasScored,
            height: "16em",
	        image: PuppyPoleVaultSuccess,
            isAnimated: true,
            lastFrame: 7,
            left: "50%",
            right: "50%",
            showScore: false,
            top: "60%",
            width: "24em",
        },
        ...puppy_winning_frame,
        {
            altText: "Puppy pole vault fail",
            animationDuration: 1.33333 * SECOND,
            duration: duration_8,
            frames: FRAMES_8,
            getCanView: handleHasNotScored,
            height: "16em",
	        image: PuppyPoleVaultFail,
            isAnimated: true,
            lastFrame: 7,
            left: "50%",
            right: "50%",
            showScore: false,
            top: "60%",
            width: "24em",
        }, {
            altText: "Puppy pole vault fail",
            animationDuration: SECOND,
            duration: SECOND,
            frames: [...FRAME_1, ...FRAME_1],
            getCanView: handleHasNotScored,
            height: "16em",
	        image: PuppyPoleVaultFailFinal,
            isAnimated: true,
            lastFrame: 0,
            left: "18%",
            showScore: true,
            width: "15em",
        }]
    }, {
        id: "puppy_swimming",
        animal: "Puppy",
        animations: [{
            altText: "Puppy swimming",
            animationDuration: animationDuration,
            canView: true,
            duration: duration_2,
            frames: FRAMES_2,
            height: HEIGHT,
            image: PuppySwim,
	        isAnimated: true,
            lastFrame: 1,
            left: "50%",
            showScore: false,
            width: "14em",
        },
        ...puppy_winning_frame,
        {
            altText: "Puppy swimming fail",
            animationDuration: animationDuration,
            duration: duration_2,
            frames: FRAMES_2,
            getCanView: handleHasNotScored,
            height: HEIGHT,
            image: PuppySwimFail,
	        isAnimated: true,
            lastFrame: 1,
            left: "30%",
            showScore: true,
            width: "14em",
        }]
    }];

    const handleGetAnimationIndex = (gameData) => {
        if (gameData?.details && props.clinicId && gameData?.data?.GameEvent?.EventId) {
            const clinicDetails = find(gameData.details, { ClinicId: props.clinicId });
            const currentEventId = gameData.data.GameEvent.EventId;

            if (toLower(clinicDetails?.AvatarType) === "cat") {
                if (currentEventId === 1) {
                    return 0;
                } else if (currentEventId === 2) {
                    return 1;
                } else if (currentEventId === 3) {
                    return 2
                } else return 3
            } else {
                if (currentEventId === 1) {
                    return 4;
                } else if (currentEventId === 2) {
                    return 5;
                } else if (currentEventId === 3) {
                    return 6
                } else return 7
            }
        }
    };

    const config = useMemo(() => {
        switch (props.game?.data?.GameEvent?.EventId) {
            case 1:
            case 3:
                return  {
                    backgroundColor: "#93a6f5",
                    backgroundAlt: "Track",
                };
            case 2:
                return  {
                    background: WoodFloor,
                    backgroundAlt: "Wood Floor",
                };
            case 4:
            default:
                return  {
                    background: Pool,
                    backgroundAlt: "Swimming Pool",
                };
        }
    }, [props.game]);


    return (
        <GenericGameCard
            {...props}
            animateAcrossScreen
            runIntoView
            animationOptions={animationOptions}
            backgroundImage={config?.background}
            backgroundColor={config?.backgroundColor}
            backgroundImageAltText={config.backgroundAlt}
            bannerImage={smallImage}
            bannerImageAltText="Golden Summer Games"
            LegalWording={GoldenSummerLegalWording}
            LeadboardLegalWording={GoldenSummerLeaderboardLegalWording}
            getAnimationIndex={handleGetAnimationIndex}
            points
            scoreStyles={{
                fontSize: "2em",
                right: "2em",
                top: "1em",
            }}
        />
    );
}

GoldenSummerGamesCard.defaultProps = {
    clinicId: null,
    dashboard: false,
    game: {},
    nodeId: null,
    snapshot: false,
    userId: null,
};

GoldenSummerGamesCard.propTypes = {
    clinicId: PropTypes.number,
    dashboard: PropTypes.bool,
    game: PropTypes.object,
    nodeId: PropTypes.number,
    snapshot: PropTypes.bool,
    userId: PropTypes.number,
};
