import React, { useMemo, useState } from "react";
import PropTypes from "prop-types";
import classnames from "classnames";
import map from "lodash/map";
import round from "lodash/round";
import DataTable from "components/common/datatable/DataTable";
import Modal from "components/common/Modal";
import Tooltip from "components/common/Tooltip";
import { addCommasToNumber } from "utils/numeric";
import {replaceProductName} from "utils/ProductUtils";

export default function GenericLeaderboardTable2024({
    clinics=  [],
    clinicId,
    game,
    isProvider,
    showAllPoints,
    snapshot,
    usePoints,
}) {
    const [dosesBreakdown, setDosesBreakdown] = useState(null);
    const [pointsBreakdown, setPointsBreakdown] = useState(null);

    const openBreakdownModal = (row, isPoints, isPrevious) => {
        if (showRowPoints(row)) {
            const clinicSales = {
                id: row.clinicId,
                current: row.currentSales,
                previous: row.previousSales,
            };
            const clinicBreakdown = isPrevious ? clinicSales.previous : clinicSales.current;

            if (isPoints) {
                setPointsBreakdown(clinicBreakdown);
            } else {
                setDosesBreakdown(clinicBreakdown);
            }
        }
    }

    const closeBreakdownModal = () => {
        setPointsBreakdown(null);
        setDosesBreakdown(null);
    }

    const columnGroupings = useMemo(() => {
        if (usePoints) {
            return [
                {
                    key: "emptyHeader",
                    title: "",
                    colSpan: 2,
                    styles: {
                        backgroundColor: "#CBC6C5"
                    },
                },
                {
                    key: "previousHeader",
                    title: "Previous Period",
                    colSpan: 2,
                    styles: {
                        backgroundColor: "#0C4DA2",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontStyle: "italic"
                    },
                },
                {
                    key: "currentHeader",
                    title: "Current Period",
                    colSpan: 4,
                    styles: {
                        backgroundColor: "#1C9DFF",
                        color: "#FFFFFF",
                        textAlign: "center",
                        fontStyle: "italic"
                    },
                }
            ];
        }
        return null;
    }, [game]);

    const showRowPoints = (row) => {
        return showAllPoints || (row.clinicId === clinicId);
    }

    const STANDARD_COLUMNS = [{
        name: "Rank",
        selector: "Place",
        key: "rank",
        sortable: false,
        searchable: false,
        colSettings: { width: "25px"},
    },{
        name: `Clinic ${isProvider ? "Name" : "Alias"}`,
        selector: "name",
        key: "name",
        sortable: false,
        searchable: false,
    }];

    const PERCENT_GROWTH_COLUMN = [{
        name: "Growth %",
        selector: "PercentGrowth",
        key: "PercentGrowth",
        sortable: false,
        searchable: false,
        colSettings: {width: "60px"},
        format: row => {
            let fontColor = "#BB0000";

            if (row.PercentGrowth > 0) {
                fontColor = "rgb(49, 125, 61)";
            }

            if (row.clinicId === clinicId) {
                fontColor = "#FFFFFF";
            }

            // If there were no previous doses then this should never show a number
            if (row.previousDoses === 0) {
                return (
                    <span style={{ color: '#000', display: "inline-block", width: "100%", textAlign: "center"}}>--</span>
                );
            }

            //  If some have been sold then show the percentage compared to last year
            if (!isNaN(row.PercentGrowth) && (row.PercentGrowth > -100)) {
                return (
                    <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>{round(row.PercentGrowth + 100)}%</span>
                );
            } else {
                // Otherwise display 0%
                return (
                    <span style={{ color: fontColor, display: "inline-block", width: "100%", textAlign: "center"}}>0%</span>
                );
            }
        }
    }];

    const PREVIOUS_PERIOD_COLUMNS = [
        {
            name: "Doses",
            selector: "previousDoses",
            key: "previousDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "60px"},
            format: row => {
                const showPoints = showRowPoints(row);
                return (
                    <div className={classnames("text-center no-wrap", {
                        "text-link": showPoints,
                    })} onClick={() => openBreakdownModal(row, false, true)}>
                        {showPoints ? addCommasToNumber(row.previousDoses) : "--"}
                    </div>
                )
            },
        },{
            name: (<div className="text-center">Points</div>),
            selector: "previousPoints",
            key: "PreviousPoints",
            sortable: false,
            searchable: false,
            format: row => {
                const showPoints = showRowPoints(row);
                return (
                    <div className={classnames("text-center no-wrap", {
                        "text-link": showPoints,
                    })} onClick={() => openBreakdownModal(row, true, true)}>
                        {showPoints ? addCommasToNumber(row.previousPoints) : "--"}
                    </div>
                )
            },
        }
    ];

    const CURRENT_PERIOD_COLUMNS = [
        {
            name: (<div className="text-center">Total Doses</div>),
            selector: "totalDoses",
            key: "TotalDoses",
            sortable: false,
            searchable: false,
            colSettings: { width: "25px"},
            format: row => {
                const showPoints = showRowPoints(row);
                return (
                    <div className={classnames("text-center no-wrap", {
                        "text-link": showPoints,
                    })} onClick={() => openBreakdownModal(row, false, false)}>
                        {showRowPoints(row) ? addCommasToNumber(row.currentDoses) : "--"}
                    </div>
                )
            },
        }, {
            name: ( <div className="text-center">Total Points</div>),
            selector: "currentPoints",
            key: "currentPoints",
            sortable: false,
            searchable: false,
            format: row => {
                const showPoints = showRowPoints(row);
                return (
                    <div
                        className={classnames("text-center no-wrap", {
                            "text-link": showPoints,
                        })}
                        style={(row.clinicId === clinicId) ? {
                            color: "#fff",
                            fontWeight: "bold",
                        } : {}}
                        onClick={() => openBreakdownModal(row, true, false)}
                    >
                        {showRowPoints(row) ? addCommasToNumber(row.currentPoints) : "--"}
                    </div>
                )
            },
        }
    ];

    const POINTS_TO_GO_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many points your clinic needs to earn to meet your points sold in the previous timeframe set for this game."
            >
                <span className="cursor-help">To Go <i className="fa fa-info-circle"/></span>
            </Tooltip>
        ),
        selector: "pointsToGo",
        key: "pointsToGo",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px" },
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.pointsToGo) : "--"}
            </div>
        ),
    }];

    const DOSES_ONLY_COLUMNS = [{
        name: "Current Doses",
        selector: "currentDoses",
        key: "currentDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.currentDoses) : "--"}
            </div>
        ),
    }, {
        name: "Previous Doses",
        selector: "previousDoses",
        key: "previousDoses",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.previousDoses) : "--"}
            </div>
        ),
    }];

    const DOSES_TO_GO_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many doses your clinic needs to sell to meet your doses sold in the previous timeframe set for this game."
            >
                <span className="cursor-help">To Go <i className="fa fa-info-circle"/></span>
            </Tooltip>
        ),
        selector: "DosesToGo",
        key: "dosesToGo",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px"},
        format: row => (
            <div className="text-center no-wrap">
                {showRowPoints(row) ? addCommasToNumber(row.dosesToGo) : "--"}
            </div>
        ),
    }];

    const TO_WIN_COLUMN = [{
        name: (
            <Tooltip
                position="left"
                tip="This column shows how many doses your clinic needs to sell to be in the lead for this game!"
            >
                <span className="cursor-help">To Win <i className="fa fa-info-circle" /></span>
            </Tooltip>
        ),
        selector: "toWin",
        key: "toWin",
        sortable: false,
        searchable: false,
        colSettings: { width: "60px" },
        format: row => (
            showRowPoints(row) ? (
                <Tooltip
                    position="left"
                    tip={(
                        <div>
                            {map(row.toWinHover, h => (
                                <div>{h}</div>
                            ))}
                        </div>
                    )}
                >
                    <div className="text-center cursor-help">
                        {row.Place === 1 ? <i className="far fa-trophy-star"/> : addCommasToNumber(row.toWin)}
                    </div>
                </Tooltip>
            ) : (
                <div className="text-center">--</div>
            )
        ),
    }];

    const TableColumns = useMemo(() => {
        if (usePoints) {
            return [
                ...STANDARD_COLUMNS,
                ...PREVIOUS_PERIOD_COLUMNS,
                ...CURRENT_PERIOD_COLUMNS,
                ...POINTS_TO_GO_COLUMN,
                ...PERCENT_GROWTH_COLUMN,
            ];
        }
        return [
            ...STANDARD_COLUMNS,
            ...DOSES_ONLY_COLUMNS,
            ...DOSES_TO_GO_COLUMN,
            ...PERCENT_GROWTH_COLUMN,
            ...TO_WIN_COLUMN,
        ];
    }, [usePoints]);

    const getConditionalRowProps = (row) => {
        if (row.clinicId === clinicId) {
            return {
                style: {
                    backgroundColor: 'rgb(26, 85, 173)',
                    color: '#F9F9FC',
                }
            };
        }
        return {};
    };

    const getClinicBreakdownData = () => {
        const isPoints = !!pointsBreakdown;
        const breakdownData = pointsBreakdown || dosesBreakdown;

        return map(breakdownData, (product, index) => (
            <tr key={`product_breakdown_${index}`}>
                <td><b>{replaceProductName(product.name)}</b></td>
                <td className="text-right">{isPoints ? addCommasToNumber(product.points) : addCommasToNumber(product.doses)}</td>
            </tr>
        ));
    }

    return (
        <>
            <DataTable
                columns={TableColumns}
                columnGroupings={columnGroupings}
                data={clinics}
                pagination={false}
                green
                useColgroup
                conditionalRowProps={getConditionalRowProps}
            />
            <Modal
                style={{ zIndex: 9999}}
                modalTitle={`${!!dosesBreakdown ? "Doses" : "Points"} by Product`}
                show={!!dosesBreakdown || !!pointsBreakdown}
                onClose={closeBreakdownModal}
                small
            >
                <div className="text-lg">
                    <table className="table table-striped table-green">
                        <tbody>
                        {getClinicBreakdownData()}
                        </tbody>
                    </table>
                </div>
            </Modal>
        </>
    );
}

GenericLeaderboardTable2024.propTypes = {
    clinicId: PropTypes.number.isRequired,
    clinics: PropTypes.arrayOf(PropTypes.object),
    game: PropTypes.object.isRequired,
    isProvider: PropTypes.bool,
    showAllPoints: PropTypes.bool,
    snapshot: PropTypes.bool,
    usePoints: PropTypes.bool,
}
