import React from 'react';
import PropTypes from 'prop-types';
import styles from './CardForm.scss';
import { CardElement } from '@stripe/react-stripe-js';
import Button from 'components/common/Button';
import TextBox from "components/common/TextBox";

export default function CardForm({
    stripeError = null,
    setStripeError = () => {},
    addCardBtnText = "Submit Card",
    handleSubmit,
    name,
    handleFormChange,
    btnDisabled,
    onCancel,
}) {
    return (
        <form
            data-testid="card_modal_component"
        >
            <div className="form-row">
                <TextBox
                    data-testid="card_modal_text_box"
                    id="name"
                    label="Name on Card"
                    name="name"
                    type={"text"}
                    value={name}
                    onChange={handleFormChange}
                    required
                    pattern={"[A-Za-z, ]+"}
                />
            </div>
            <div className="form-row">
                <label htmlFor="card-element">
                    Credit or debit card
                </label>
                <CardElement
                    id="card-element"
                    onChange={setStripeError}
                    className={styles.cardEl}
                />
                <div className="card-errors" role="alert">{stripeError}</div>
            </div>
            <div className="flex spaced-content justify-flex-end margin-top-md">
                <Button
                    data-testid="card_modal_cancel_button"
                    large
                    disabled={btnDisabled}
                    onClick={onCancel}
                    type="gray"
                >
                    Cancel
                </Button>

                <Button
                    data-testid="card_modal_button"
                    large
                    onClick={handleSubmit}
                    disabled={btnDisabled}
                >
                    {addCardBtnText || "Submit Card"}
                </Button>
            </div>
        </form>
    )
}

CardForm.propTypes = {
    onCancel: PropTypes.func.isRequired,
    name: PropTypes.string.isRequired,
    handleFormChange: PropTypes.func.isRequired,
    handleSubmit: PropTypes.func.isRequired,
    stripeError: PropTypes.string,
    setStripeError: PropTypes.func,
    btnDisabled: PropTypes.bool,
    addCardBtnText: PropTypes.string,
}
