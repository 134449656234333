import React from "react";
import PropTypes from "prop-types";
import find from "lodash/find";
import findIndex from "lodash/findIndex";
import get from "lodash/get";
import toLower from "lodash/toLower";
import GenericGameCard from "components/games/elements/GenericGameCard";
import GrandSlamLegalWording, { GrandSlamLeaderboardLegalWording } from "../LegalWording/GrandSlamLegalWording";
import {
    BANNER,
    KITTY_HOME_RUN_ANIM,
    KITTY_HOME_RUN_FINISH_ANIM,
    KITTY_PITCHING_SHEET_ANIM,
    KITTY_RUN_SLIDE_ANIM,
    KITTY_SWING_HIT_ANIM,
    KITTY_SWING_MISS_ANIM,
    PUPPY_HOME_RUN_ANIM,
    PUPPY_HOME_RUN_FINISH_ANIM,
    PUPPY_PITCHING_SHEET_ANIM,
    PUPPY_RUN_SLIDE_ANIM,
    PUPPY_SWING_HIT_ANIM,
    PUPPY_SWING_MISS_ANIM,
    UMPIRE_SAFE_ANIM,
    UMPIRE_STRIKE_ANIM
} from "components/games/elements/GrandSlamGameCard/GrandSlamAnimations";

const ANIMATION_OPTIONS = [
    {
        id: "kitty_strike",
        animal: "Kitty",
        animations: [
            ...PUPPY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...KITTY_SWING_MISS_ANIM,
            ...UMPIRE_STRIKE_ANIM,
        ],
    },
    {
        id: "kitty_safe",
        animal: "Kitty",
        animations: [
            ...PUPPY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...KITTY_SWING_HIT_ANIM,
            ...KITTY_RUN_SLIDE_ANIM,
            ...UMPIRE_SAFE_ANIM,
        ],
    },
    {
        id: "kitty_home_run",
        animal: "Kitty",
        animations: [
            ...PUPPY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...KITTY_SWING_HIT_ANIM,
            ...KITTY_HOME_RUN_ANIM,
            ...KITTY_HOME_RUN_FINISH_ANIM,
        ],
    },

    {
        id: "puppy_strike",
        animal: "Puppy",
        animations: [
            ...KITTY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...PUPPY_SWING_MISS_ANIM,
            ...UMPIRE_STRIKE_ANIM,
        ],
    },
    {
        id: "puppy_safe",
        animal: "Puppy",
        animations: [
            ...KITTY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...PUPPY_SWING_HIT_ANIM,
            ...PUPPY_RUN_SLIDE_ANIM,
            ...UMPIRE_SAFE_ANIM,
        ],
    },
    {
        id: "puppy_home_run",
        animal: "Puppy",
        animations: [
            ...KITTY_PITCHING_SHEET_ANIM,
            // ...BASEBALL_PITCH_ANIM,
            ...PUPPY_SWING_HIT_ANIM,
            ...PUPPY_HOME_RUN_ANIM,
            ...PUPPY_HOME_RUN_FINISH_ANIM,
        ],
    },
];

export default function GrandSlamGameCard({
    clinicId,
    game,
    ...otherProps
}) {
    const handleGetAnimationIndex = (gameData) => {
        if (clinicId) {
            const clinicDetails = find(gameData.details, { ClinicId: clinicId });

            if (toLower(clinicDetails?.AvatarType) === "cat") {
                if (get(clinicDetails, "Doses", 0) === 0) {
                    return findIndex(ANIMATION_OPTIONS, { id: "kitty_strike" });
                }

                if (Math.random() > 0.5) {
                    return findIndex(ANIMATION_OPTIONS, { id: "kitty_safe" });
                }

                return findIndex(ANIMATION_OPTIONS, { id: "kitty_home_run" });
            } else {
                if (get(clinicDetails, "Doses", 0) === 0) {
                    return findIndex(ANIMATION_OPTIONS, { id: "puppy_strike" });
                }

                if (Math.random() > 0.5) {
                    return findIndex(ANIMATION_OPTIONS, { id: "puppy_safe" });
                }

                return findIndex(ANIMATION_OPTIONS, { id: "puppy_home_run" });
            }
        }
    };

    return (
        <GenericGameCard
            {...{
                clinicId,
                game,
                otherProps,
            }}
            animationOptions={ANIMATION_OPTIONS}
            bannerImage={BANNER}
            bannerImageAltText="Grand Slam Game"
            LegalWording={GrandSlamLegalWording}
            LeadboardLegalWording={GrandSlamLeaderboardLegalWording}
            getAnimationIndex={handleGetAnimationIndex}
            scoreStyles={{
                fontSize: "1.5em",
                right: "15%",
                top: "30%",
            }}
            points
        />
    );
}

GrandSlamGameCard.propTypes = {
    clinicId: PropTypes.number,
    game: PropTypes.object,
    dashboard: PropTypes.bool,
    nodeId: PropTypes.number,
    snapshot: PropTypes.bool,
    userId: PropTypes.number,
};
