import React, { useEffect, useMemo, useState } from "react";
import { connect } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import classnames from "classnames";
import styles from "./GenericGameCard.scss";
import filter from "lodash/filter";
import find from "lodash/find";
import get from "lodash/get";
import { getLast2Snapshots } from "actions/ProviderActions";
import Image from "components/common/Image";
import Loader from "components/common/Loader";
import MultipleAnimations from "components/games/elements/GenericGame/MultipleAnimations";
import { PermissionTypes, userHasPermission, userPrimaryLocationType } from "utils/permissions/rolesPermissions";
import * as LocationTypes from "constants/LocationTypes";
import * as UserPermissions from "constants/UserPermissions";

function GenericGameCard(props) {
    const {
        animateAcrossScreen,
        animationContainerStyles = {},
        animationOptions = [],
        backgroundColor = "white",
        backgroundImage,
        bannerImage,
        bannerImageAltText = "",
        clinicId = null,
        dashboard,
        game = {},
        gameSnapshotComparison,
        getAnimationIndex,
        getSnapshots,
        isAdmin,
        isProvider,
        LegalWording,
        points,
        runIntoView,
        scoreStyles = {},
    } = props;
    const history = useHistory();
    const params = useParams();
    const nodeId = Number(params?.nodeId) || null;
    const [gameLoaded, setGameLoaded] = useState(false);
    const [restartCounter, setRestartCounter] = useState(0);
    const [showScore, setShowScore] = useState(false);
    const [showRestart, setShowRestart] = useState(false);
    const [backgroundConfig, setBackgroundConfig] = useState();
    const [animationText, setAnimationText] = useState();

    useEffect(() => {
        if (game?.greenlineGameId && game?.daysFromStart !== 0) {
            getSnapshots(game.greenlineGameId, game.ownerUserId, clinicId, false);
        }
    }, [game]);

    const handleDashboardClick = () => {
        if (isAdmin) {
            history.push(`/admin/clinic/${clinicId}/games/${game.greenlineGameId}`);
        } else if(isProvider) {
            history.push(`/provider/location/${nodeId}/clinic/${clinicId}/games/${game.greenlineGameId}`);
        } else {
            history.push(`/games/${game.greenlineGameId}`);
        }
    }

    const handleRestart = () => {
        setShowRestart(false);
        setShowScore(false);
        const nextRestart = restartCounter + 1;
        setRestartCounter(nextRestart);
    };

    const gameData = useMemo(() => {
        if (game?.daysFromStart === 0) {
            // Return kickoff
            return {
                totalDoses: 0,
                totalPoints: 0,
                newDoses: 0,
                newPoints: 0,
                bonus: 0,
                bonusPoints: 0,
                firstDay: true,
            }
        }
        if (game?.data.Clinics && clinicId && !!gameSnapshotComparison) {
            if (!gameSnapshotComparison.length) {
                // Unlikely, but just in case gameSnapshotComparison
                return {
                    totalDoses: 0,
                    totalPoints: 0,
                    newDoses: 0,
                    newPoints: 0,
                    bonus: 0,
                    bonusPoints: 0,
                    firstDay: false,
                }
            }

            let today;
            let yesterday;
            if (gameSnapshotComparison.length === 1) {
                // Handle when it's the first day, all 0s for Yesterday
                yesterday = gameSnapshotComparison[0];
                today = find(game.data.Clinics, clinic => clinic.ClinicId === clinicId);
            } else {
                yesterday = find(gameSnapshotComparison[0].data.Clinics, clinic => clinic.ClinicId === clinicId);
                today = find(gameSnapshotComparison[1].data.Clinics, clinic => clinic.ClinicId === clinicId);
            }

            const todayTotalDoses = get(today, "CurrentPeriod.TotalReportDoses", 0);
            const yesterdayTotalDoses = get(yesterday, "CurrentPeriod.TotalReportDoses", 0);
            const newDoses = todayTotalDoses - yesterdayTotalDoses;

            const todayTotalPoints = get(today, "CurrentPeriod.TotalReportPoints", 0);
            const yesterdayTotalPoints = get(yesterday, "CurrentPeriod.TotalReportPoints", 0);
            const newPoints = todayTotalPoints - yesterdayTotalPoints;

            const todayExtraDoses = get(today, "CurrentPeriod.ExtraDoses", 0);
            const yesterdayExtraDoses = get(yesterday, "CurrentPeriod.ExtraDoses", 0);
            const bonus = todayExtraDoses - yesterdayExtraDoses;

            const todayExtraPoints = get(today, "CurrentPeriod.ExtraPoints", 0);
            const yesterdayExtraPoints = get(yesterday, "CurrentPeriod.ExtraPoints", 0);
            const bonusPoints = todayExtraPoints - yesterdayExtraPoints;

            return {
                totalDoses: todayTotalDoses,
                totalPoints: todayTotalPoints,
                newDoses: newDoses,
                newPoints: newPoints,
                bonus: bonus,
                bonusPoints: bonusPoints,
                firstDay: false,
            };
        }
        return null;
    }, [game, clinicId, gameSnapshotComparison]);

    useEffect(() => {
        if (game?.details && (gameSnapshotComparison || game?.daysFromStart === 0) && !!gameData) {
            setGameLoaded(true);
        }
    }, [game, gameSnapshotComparison, gameData]);

    const getAnimations = (game) => {
        if (!!getAnimationIndex) {
            // This is used to get an animation based on a certain piece of data. It returns the index of the animation.
            const index = getAnimationIndex(game);
            if (!animationOptions?.[index]) {
                console.error("The index", index, "doesn't exist:", animationOptions);
                return animationOptions[0];
            }
            return animationOptions[index];
        } else {
            // By default, return only the first animation
            return 0;
        }
    };

    const filteredAnimations = useMemo(() => {
        const animations = getAnimations(game);
        return filter(animations.animations, a => {
            let canView = a.canView || false;
            if (!!a.getCanView) {
                canView = a.getCanView(game, gameSnapshotComparison);
            }
            return canView;
        });
    }, [animationOptions, game, gameSnapshotComparison]);

    const handleBeforeAnimation = (index) => {
        const animation = filteredAnimations[index];

        if (animation) {
            setShowScore(animation.showScore);
            setAnimationText(animation.text); // Hacky to bring animation state here, but not much choice without a bigger refactor.

            if (animation.background) {
                const cfg = {
                    ...animation.background,
                    style: {
                        // Legacy anims scroll and start positioned left -250%, but new anims shouldn't be offset by default, since they optionally don't scroll.
                        left: "0", // Config can override `left` but we want to make sure it defaults to 0, in case you forget to put it in the anim config data.
                        ...get(animation, "background.style", {}),
                    },
                    isFromAnim: true,
                };
                setBackgroundConfig(cfg);
            } else {
                usePropsBackgroundConfig();
            }
        } else {
            usePropsBackgroundConfig();
        }
    };

    const usePropsBackgroundConfig = () => {
        setBackgroundConfig({
            backgroundImage,
            backgroundAlt: bannerImageAltText,
            backgroundColor,
        });
    };

    const handleAnimationComplete = (index) => {
        if ((index + 1) === filteredAnimations?.length) {
            setShowRestart(true);
        }
    }

    const scrollBackgroundConfig = get(backgroundConfig, "scrollBackground");
    const scrollBackground = scrollBackgroundConfig === true || (scrollBackgroundConfig === undefined && !showScore);
    const backgroundAsset = get(backgroundConfig, "backgroundImage") || backgroundImage; // Anim config overrides props value.
    const backgroundStyle = get(backgroundConfig, "style");

    return (
        <div
            key={`game_${game.greenlineGameId}`}
            className={classnames(styles.root, {
                [styles.clickable]: dashboard,
                [styles.historic]: !game.isCurrent,
            })}
        >
            <div id={`game_${game.greenlineGameId}`} className={styles.gameContainer}>
                <div className={styles.banner}>
                    <Image
                        src={bannerImage}
                        alt={bannerImageAltText}
                    />
                </div>
                <div
                    className={classnames(styles.animation, {
                        [styles.animate]: !animateAcrossScreen && points ? (gameData?.newPoints > 0) : (gameData?.newDoses > 0),
                        [styles.runIntoView]: runIntoView,
                        [styles.animateAcrossScreen]: (animateAcrossScreen && !showRestart),
                        [styles.animateDoses]: (showScore && !showRestart),
                        [styles.hideDoses]: (!showScore),
                        [styles.scrollBackground]: scrollBackground,
                    })}
                    style={{ backgroundColor: get(backgroundConfig, "backgroundColor", "white") }}
                >
                    {backgroundAsset ? (
                        <>
                            <Image
                                className={styles.backgroundImage}
                                style={backgroundStyle}
                                src={backgroundAsset}
                                alt={get(backgroundConfig, "backgroundAlt")}
                            />
                            <div>{backgroundAsset}</div>
                        </>
                    ) : <div>{backgroundAsset}</div>}
                    <div
                        className={styles.animationContainer}
                        style={animationContainerStyles}
                    >
                        {gameLoaded ? (
                            <MultipleAnimations
                                key={restartCounter}
                                animations={filteredAnimations}
                                onBeforeAnimation={handleBeforeAnimation}
                                onAnimationCompleted={handleAnimationComplete}
                            />
                        ) : (
                            <Loader />
                        )}
                    </div>
                    {points ? (
                        <div className={styles.score} style={scoreStyles}>
                            <div className={styles.newPoints}>+{gameData?.newPoints || 0} POINT{Number(gameData?.newPoints || 0) === 1 ? "" : "S"}</div>
                            <div className={styles.doses}>
                                <div>POINTS <i className={classnames("fa fa-paw", styles.paw)} /></div>
                                <div className="text-lg">{gameData?.totalPoints || 0}</div>
                            </div>
                        </div>
                    ) : (
                        <div className={styles.score} style={scoreStyles}>
                            {!!animationText ? (
                                <div className={styles.resultText}>
                                    {animationText}
                                </div>
                            ) : null}
                            <div className={styles.newDoses}>+{gameData?.newDoses || 0} DOSE{Number(gameData?.newDoses || 0) === 1 ? "" : "S"}</div>
                            <div className={styles.doses}>
                                <div>DOSES <i className={classnames("fa fa-paw", styles.paw)} /></div>
                                <div className="text-lg">{gameData?.totalDoses || 0}</div>
                            </div>
                        </div>
                    )}
                    {(showRestart) && (
                        <div
                            className={styles.restart}
                            onClick={handleRestart}
                        >
                            <i className="fa fa-rotate-right"/>
                        </div>
                    )}
                </div>
                <div
                    className={styles.gameDetails}
                >
                    <div className={styles.dates}>
                        {game.startDate} - {game.endDate}
                    </div>
                    {!!LegalWording && (
                        <LegalWording />
                    )}
                    <div className={styles.viewLeaderboardLink} onClick={handleDashboardClick}>
                        View the Leaderboard <i className={"fa-solid fa-chevron-right"} />
                    </div>
                </div>
            </div>
        </div>
    );
}

GenericGameCard.propTypes = {
    animateAcrossScreen: PropTypes.bool,
    animationContainerStyles: PropTypes.object,
    animationOptions: PropTypes.array,
    backgroundColor: PropTypes.string,
    backgroundImage: PropTypes.string,
    bannerImage: PropTypes.string,
    bannerImageAltText: PropTypes.string,
    clinicId: PropTypes.number,
    dashboard: PropTypes.bool,
    game: PropTypes.object,
    getAnimationIndex: PropTypes.func,
    legalWording: PropTypes.node,
    points: PropTypes.bool, // If this is true, the game uses points otherwise, it uses doses.
    runIntoView: PropTypes.bool, // Character moves in from the side at start of animation
    scoreStyles: PropTypes.object,
};

export default connect(
    (state, ownProps) => {
        const userProfile = state.user.userProfile;
        const isAdmin = userPrimaryLocationType(userProfile, [LocationTypes.NULL]);
        const isProvider = userPrimaryLocationType(userProfile, [LocationTypes.PROVIDER]);
        const canViewClinicBIGamesDashboard = userHasPermission(PermissionTypes.VIEW, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);
        const canEditClinicBiGamesDashboard = userHasPermission(PermissionTypes.EDIT, UserPermissions.CLINIC_BI_GAMES_DASHBOARD, userProfile);

        return {
            isAdmin,
            isProvider,
            canViewClinicBIGamesDashboard,
            canEditClinicBiGamesDashboard,
            gameSnapshotComparison: state.entities.gameSnapshotComparison[ownProps.game?.greenlineGameId],
        }
    },
    (dispatch) => ({
        getSnapshots: (gameId, userId, clinicId, isDemo) => dispatch(getLast2Snapshots(gameId, userId, clinicId, isDemo)),
    })
)(GenericGameCard);
